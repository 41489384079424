import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { NavbarData } from '../assets/Datas/NavbarDatas'
import { useNavigate } from 'react-router-dom'

import { animateScroll as scroll } from 'react-scroll'

import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavImg,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from './NavbarElements'

const Navbar = (props) => {
  let navigate = useNavigate()
  const routeChange = () => {
    let path = '/maintenance'
    navigate(path)
  }
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }
  const toggleHome = () => {
    scroll.scrollToTop()
  }
  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/'>
            <NavImg
              src={require('../assets/images/logo-img.png')}
              onClick={toggleHome}
            />
          </NavLogo>
          <MobileIcon onClick={props.toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {NavbarData.map((item) => (
              <>
                {item.id === `${props.info}` ? (
                  <>
                    {item.navbarMenu.map((item2) => (
                      <>
                        <NavItem>
                          <NavLinks
                            to={item2.navLink}
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                          >
                            {item2.navName}
                          </NavLinks>
                        </NavItem>
                      </>
                    ))}
                  </>
                ) : (
                  // (
                  //   <>
                  //     {item.id === 'community' ? (
                  //       <>
                  //         {item.navbarMenu.map((item2) => (
                  //           <>
                  //             <NavItem>
                  //               <NavLinks to={item2.navLink}>
                  //                 {item2.navName}
                  //               </NavLinks>
                  //             </NavItem>
                  //           </>
                  //         ))}
                  //       </>
                  //     ) : (
                  //       <>
                  //         {item.id === 'discover' ? (
                  //           <>
                  //             {item.navbarMenu.map((item2) => (
                  //               <>
                  //                 <NavItem>
                  //                   <NavLinks to={item2.navLink}>
                  //                     {item2.navName}
                  //                   </NavLinks>
                  //                 </NavItem>
                  //               </>
                  //             ))}
                  //           </>
                  //         ) : (
                  //           <>
                  //             {item.id === 'playground' ? (
                  //               <>
                  //                 {item.navbarMenu.map((item2) => (
                  //                   <NavItem>
                  //                     <NavLinks to={item2.navLink}>
                  //                       {item2.navName}
                  //                     </NavLinks>
                  //                   </NavItem>
                  //                 ))}
                  //               </>
                  //             ) : (
                  //               ''
                  //             )}
                  //           </>
                  //         )}
                  //       </>
                  //     )}
                  //   </>
                  // )
                  ''
                )}
              </>
            ))}
            {/* {`${props.info}` === 'Home' ? (
              <>
                <NavBtn>
                  <NavBtnLink to='/'>Launch App</NavBtnLink>
                </NavBtn>
              </>
            ) : (
              ''
            )} */}
            {/* <NavItem>
              <NavLinks to='playground'>Playground</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='community'>Community</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='discover'>Discover</NavLinks>
            </NavItem> */}
          </NavMenu>
          {`${props.info}` === 'Home' ? (
            <>
              <NavBtn>
                <NavBtnLink to='/' onClick={routeChange}>
                  Launch App
                </NavBtnLink>
              </NavBtn>
            </>
          ) : (
            ''
          )}
          {/* <NavBtn>
            <NavBtnLink to='/'>Launch App</NavBtnLink>
          </NavBtn> */}
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
