import styled from 'styled-components'

export const ButtonType1 = styled.button`
  text-decoration: none;
  border-radius: 6px;
  background: #352e68;
  white-space: nowrap;
  padding: 12px 32px;
  width: 153px;
  height: 62px;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    /* background: #fff; */
    /* color: #010606; */
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    padding: 12px 20px;
    width: 110px;
    height: 48px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }
`
export const ButtonType2 = styled.div`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px;
  width: 169px;
  height: 41px;
  color: #fff;
  cursor: pointer;

  background: linear-gradient(0deg, #352e68, #352e68),
    linear-gradient(0deg, #352e68, #352e68),
    linear-gradient(0deg, #352e68, #352e68), #352e68;
  border-radius: 6px;
`
