import React, { useState } from 'react'
import privacy from '../../Components/assets/PdfFiles/privacy.pdf'

import { Worker } from '@react-pdf-viewer/core'
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'

//import of default layout plugin
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import styled from 'styled-components'

const InfoContainer = styled.div`
  background: var(--secondaryBg);
  border-radius: 20px;
  margin-top: 80px;
  overflow: hidden;
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    padding: 100px 0;
    margin-left: 0;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.h1`
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 32px;
  }
`
const PdfViewer = styled.div`
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  height: 800px;
  @media screen and (max-width: 460px) {
    margin-top: 20px;
  }
`

const PrivacyPolicy = () => {
  //creating instance for default layout of pdf viewer
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Privacy and Policy</SectionTitle>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
            {/* viewing components */}
            <PdfViewer>
              <Viewer
                plugins={[defaultLayoutPluginInstance]}
                fileUrl={privacy}
              />
            </PdfViewer>
          </Worker>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default PrivacyPolicy
