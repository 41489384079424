import styled from 'styled-components'

export const InfoContainer = styled.div`
  position: relative;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -50px;
  /* height: 700px; */
  overflow: hidden;
  z-index: 100 !important;
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`

export const InfoWrapper = styled.div`
  border-radius: 10px;
  background: var(--primaryBg);
  display: flex;
  z-index: 1;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
`
export const InfoContent = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-between;
  /* padding: 50px; */
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`

export const Column1 = styled.div`
  width: 50%;
  margin-bottom: 15px;
  padding: 50px;
  @media screen and (max-width: 815px) {
    width: 100%;
  }
`
export const TextContent = styled.div`
  @media screen and (max-width: 460px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;

    /* or 133% */
    text-align: center;
  }
`
export const Description = styled.p`
  color: var(--primaryColor);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin: 30px 0px;
  @media screen and (max-width: 815px) {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;

    /* or 178% */
    text-align: center;
  }
`
export const ImgScreenWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media screen and (max-width: 815px) {
    display: flex;
  }
`
export const ImgScreen = styled.img`
  margin-bottom: 10px;
`
export const ButtonContent = styled.div``

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`
export const BtnImg = styled.img`
  width: 150px;
  height: 80px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`
export const Psign = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    text-align: center;
  }
`
export const Column2 = styled.div`
  width: 50%;
  @media screen and (max-width: 815px) {
    display: none;
  }
`
export const ImgWrapper = styled.div`
  /* border: 1px solid red; */
  margin-right: 50px;
  margin-bottom: 0px;
  @media screen and (max-width: 480px) {
    margin-right: 0px;
  }
`

export const Img = styled.img`
  width: 100%;
  height: 700px;
  @media screen and (max-width: 480px) {
    margin-top: 15px;
  }
`
