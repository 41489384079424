import styled from 'styled-components'
import halfbg from '../assets/images/tryimage.png'
import halfbg2 from '../assets/images/tryimage2.png'

export const InfoContainer = styled.div`
  z-index: 1;
  overflow: hidden;
  display: flex;
  max-width: 1500px;
  margin: auto;
  margin-right: auto;
  margin-left: auto;
  /* left: 20px; */
  background: url(${halfbg2}) right -15em bottom 12em no-repeat;
  background-size: 750px 750px;
  color: #fff;
  background-clip: content-box;
  /* background-image: url(${halfbg});
  background-position: 0px 0px, right 3em bottom 2em; */
  @media screen and (max-width: 815px) {
    padding: 50px 0;
    background: #fff;
    margin-left: 0;
  }
  @media screen and (max-width: 460px) {
    padding: 100px 0;
  }
`

export const InfoWrapper = styled.div`
  background: url(${halfbg}) left -20em top 7em no-repeat;
  background-size: 700px 700px;
  display: flex;
  z-index: 1;
  overflow: hidden;
  height: 815px;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 815px) {
    background: url(${halfbg}) left -19em top 5em no-repeat;
    background-size: 800px 800px;
  }
  @media screen and (max-width: 460px) {
    background: url(${halfbg}) left -15em top 1em no-repeat;
    background-size: 500px 500px;
    height: 600px;
  }
`

export const InfoRow = styled.div`
  width: 100%;
  padding-top: 100px;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';
  /* grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`}; */
  @media screen and (max-width: 480px) {
    grid-template-areas: 'col1' 'col2';
    padding-top: 50px;
  }
  @media screen and (max-width: 815px) {
    grid-template-areas: 'col1' 'col1';
  }
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  @media screen and (max-width: 815px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  @media screen and (max-width: 815px) {
    display: none;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  /* justify-content: center; */
  align-items: center;
`

export const Heading = styled.h1`
  align-items: center;

  margin-bottom: 24px;
  font-size: 72px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.h2`
  max-width: 500px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 80px;
  text-align: center;
  color: var(--tertiaryColor);
  @media screen and (max-width: 480px) {
    font-size: 15px;
    line-height: 50px;
  }
`
export const HeroImg = styled.img`
  display: none;
  width: 100%;
  height: 350px;
  @media screen and (max-width: 815px) {
    display: flex;
  }
  @media screen and (max-width: 460px) {
    height: 270px;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  @media screen and (max-width: 480px) {
    margin-left: -30px;
    margin-top: 20px;
  }
`
export const Btn = styled.div`
  margin-left: 30px;
`

export const ImgWrap = styled.div`
  /* max-width: 555px; */
  height: 100%;
`

export const Img = styled.img`
  width: 120%;
  height: 575px;
  margin: 0 0 10px 0;
  margin-left: -160px;
  padding-right: 0;
  @media screen and (max-width: 480px) {
    display: none;
    /* margin-top: -50px; */
  }
`
