export const NavbarData = [
  {
    id: 'Home',
    navbarMenu: [
      {
        navName: 'Playground',
        navLink: 'id_playground',
      },
      { navName: 'Discover', navLink: 'id_discover' },
      { navName: 'Community', navLink: 'id_footer' },
    ],
  },
  {
    id: 'Playground',
    navbarMenu: [
      { navName: 'Journeys', navLink: '/' },
      { navName: 'Marketplace', navLink: '/' },
      { navName: 'Security', navLink: '/' },
    ],
  },
  {
    id: 'Community',
    navbarMenu: [
      {
        navName: 'Governance',
        navLink: '/community/governance',
      },
      { navName: 'Social', navLink: '/' },
      { navName: 'News', navLink: '/community/news' },
      { navName: 'Press', navLink: '/community/press' },
      { navName: 'Contact Us', navLink: '/' },
    ],
  },
  {
    id: 'Discover',
    navbarMenu: [
      { navName: 'FAQ', navLink: '/discover/faq' },
      { navName: 'Relationship', navLink: '/discover/relationship' },
      { navName: 'Team', navLink: '/discover/team' },
      { navName: 'Tokenomics', navLink: '/discover/tokenomics' },
      {
        navName: 'Whitepaper',
        navLink: '/discover/whitepaper',
      },
    ],
  },
]
