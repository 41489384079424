import React, { useState } from 'react'
import Navbar from '../../Navbar'
import Roadmap from '../../Roadmap'
import Sidebar from '../../Sidebar'
import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
  InfoRow1,
  Column1,
  HeadingOne,
  SubHeadingOne,
  SmartDiv,
  Column2,
  TableName,
  TableHeading,
  TableRow,
  DataCenter,
  TableRowHeading,
  TableData,
  InfoRow2,
  InfoRow2Wrapper,
  RoadmapWrapper,
  Details,
  DetailTitle,
  DetailsP,
  RoadMapImg,
  Col2,
  Col1,
  Col3,
  Col4,
  InfoRow3,
  SupportImgContainer,
  Img1,
} from '../Tokenomics/TokenomicsElements'
const Tokenomic = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Tokenomics</SectionTitle>
          <InfoRow1>
            <Column1>
              <HeadingOne>Governance Token CPLAY</HeadingOne>
              <SubHeadingOne>
                CPLAY network Is going to establish a privacy-protected crypto
                ecosystem where the entire ecosystem will be driven by Its
                governance token CPLAY. As a result, our network’s in-house
                products will accept payment through CPIAV token besides other
                cryptocurrencies. CPLAY have a total supply of 500000000 and is
                built on worlds most popular Binance smart chain. Token supply
                is limited and a specific amount of token has been allocated for
                IDO & lEO sale.
              </SubHeadingOne>
              <SmartDiv>
                <HeadingOne>Smart Contract </HeadingOne>
                <SubHeadingOne>
                  0x740E08E52542Dfl9D7f2f9cBF3924ac0Bfe53071
                </SubHeadingOne>
              </SmartDiv>
            </Column1>
            <Column2>
              <TableName>
                <TableRowHeading>
                  <TableHeading>Token Name</TableHeading>
                  <TableHeading>CPLAY Network</TableHeading>
                </TableRowHeading>
                <br />
                <TableRow>
                  <DataCenter>
                    <TableData>Token Chain</TableData>
                    <TableData>Binance Smart Chain</TableData>
                  </DataCenter>
                </TableRow>
                <TableRow>
                  <DataCenter>
                    <TableData>Token Supply</TableData>
                    <TableData>500,000,000</TableData>
                  </DataCenter>
                </TableRow>
                <TableRow>
                  <DataCenter>
                    <TableData>Contract Address</TableData>
                    <TableData>
                      0x740E08E52542Dfl9D7f2f9cBF3924ac0Bfe53071
                    </TableData>
                  </DataCenter>
                </TableRow>
                <TableRow>
                  <DataCenter>
                    <TableData>Decimal</TableData>
                    <TableData>8</TableData>
                  </DataCenter>
                </TableRow>
                <TableRow>
                  <DataCenter>
                    <TableData>----------</TableData>
                    <TableData>----------</TableData>
                  </DataCenter>
                </TableRow>
              </TableName>
            </Column2>
          </InfoRow1>
          <Roadmap />
          <InfoRow3>
            <HeadingOne>Supported Wallet </HeadingOne>
            <SupportImgContainer>
              <Img1 />
              <Img1 />
              <Img1 />
              <Img1 />
              <Img1 />
              <Img1 />
              <Img1 />
              <Img1 />
            </SupportImgContainer>
          </InfoRow3>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Tokenomic
