import styled from 'styled-components'

export const InfoContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin-top: 100px;
  margin-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 400px) {
    /* padding: 200px 0; */
    margin-top: 0;
    margin-bottom: -100px;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: auto;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  /* @media screen and (max-width: 780px) {
    height: 795px;
  } */
`

export const SectionHeading = styled.h1`
  font-size: 58px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--primaryColor);
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const InfoContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const InfoImg = styled.img`
  width: 307px;
  height: 294px;
  /* width: 100%; */
  @media screen and (max-width: 815px) {
    width: 200px;
    height: 180px;
  }
  @media screen and (max-width: 460px) {
    margin: 0;
    margin-left: -50px;
    width: 250px;
  }
`
export const InfoTextWrapper = styled.div`
  padding: 20px;
`
export const InfoImg2 = styled.img`
  width: 307px;
  @media screen and (max-width: 815px) {
    width: 200px;
    height: 180px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`
export const Description = styled.p`
  color: var(--primaryColor);
  font-size: 18px;
  line-height: 32px;
`
