export const cryptonews_api = [
  {
    news_url:
      'https://cryptoslate.com/el-salvador-releases-mock-up-of-bitcoin-city-whats-the-verdict/',
    image_url:
      'https://crypto.snapi.dev/images/v1/l/3/image-2022-05-10-203048025-139129.png',
    title: "El Salvador releases mock-up of Bitcoin City – what's the verdict?",
    text: "El Salvador's President Bukele tweeted images of an architect's model of Bitcoin City and the caption that things are ‘coming along beautifully.' #Bitcoin City is coming along beautifully ❤️ pic.twitter dot com/A6ay8aAREW — Nayib Bukele (@nayibbukele) May 9, 2022 Bitcoin City is a futuristic concept The pictures show a golden disc-shaped model with a hexagonal center and […] The post El Salvador releases mock-up of Bitcoin City – what's the verdict?",
    source_name: 'CryptoSlate',
    date: 'Tue, 10 May 2022 19:31:16 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://www.forbes.com/sites/cbovaird/2022/05/10/where-are-bitcoin-prices-heading-next-after-falling-below-30000/',
    image_url:
      'https://crypto.snapi.dev/images/v1/s/s/where-are-bitcoin-prices-heading-next-after-falling-below-30000-139126.jpg',
    title: 'Where Are Bitcoin Prices Heading Next After Falling Below $30,000?',
    text: "Bitcoin prices fell to their lowest since July yesterday. What's next for the digital currency? Several experts offer their input.",
    source_name: 'Forbes',
    date: 'Tue, 10 May 2022 19:11:08 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://cryptobriefing.com/michael-saylor-defends-bitcoin-backed-loan/?utm_source=feed&utm_medium=rss',
    image_url:
      'https://crypto.snapi.dev/images/v1/p/b/saylor-microstrategy-bitcoin-cover-838x440-139127.jpeg',
    title: 'Michael Saylor Defends Bitcoin-Backed Loan',
    text: "Saylor intends to maintain MicroStrategy's Bitcoin position by adding Bitcoin, if necessary.",
    source_name: 'Crypto Briefing',
    date: 'Tue, 10 May 2022 19:08:17 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://www.newsbtc.com/news/bitcoin/market-downtrend-trigger-bitcoin-inflows-from-institutional-investors/',
    image_url:
      'https://crypto.snapi.dev/images/v1/p/k/bitcoin-4-scaled-139124.jpeg',
    title:
      'Market Downtrend Trigger Bitcoin Inflows From Institutional Investors',
    text: 'The market continues to be in disarray as the price of bitcoin has now fallen to the low $30,000s. This had been preceded by declining faith in the market thus translating to fewer inflows/more outflows in the last couple of weeks.',
    source_name: 'NewsBTC',
    date: 'Tue, 10 May 2022 19:00:40 -0400',
    topics: ['institutions'],
    sentiment: 'Negative',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://www.cryptoglobe.com/latest/2022/05/40-of-bitcoin-btc-investors-are-now-sitting-on-unrealized-losses/',
    image_url:
      'https://crypto.snapi.dev/images/v1/v/u/willy-woo-bitcoin-exit-strategy-139100.jpg',
    title:
      '40% of Bitcoin ($BTC) Investors Are Now Sitting on Unrealized Losses',
    text: "The price of Bitcoin ($BTC) recently plunged to $31,000 after a series of withdrawals on the Anchor Protocol sled to the largest decentralized stablecoin, UST, losing its peg. Bitcoin's crash has seen 40% of investors in the cryptocurrency sit on unrealized losses. In response to the UST crisis, the Luna Foundation Guard deployed over 28,200 […]",
    source_name: 'CryptoGlobe',
    date: 'Tue, 10 May 2022 17:50:00 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url: 'https://coingape.com/?p=112165',
    image_url: 'https://crypto.snapi.dev/images/v1/i/1/btc-139099.jpg',
    title: 'New Data Signals Institutional Players Are Offloading Bitcoin',
    text: 'Institutional investors are reportedly contributing largely to selling pressures on the negative price premium. Negative trading pressures from institutional investors Compared to retail investors, institutional investors do not sell or buy in small quantities.',
    source_name: 'Coingape',
    date: 'Tue, 10 May 2022 17:49:18 -0400',
    topics: ['institutions'],
    sentiment: 'Negative',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://www.thecryptoupdates.com/el-salvador-plans-a-bitcoin-city/',
    image_url: 'https://crypto.snapi.dev/images/v1/f/q/ss-480x320-139098.jpg',
    title: 'El Salvador Plans A Bitcoin City',
    text: "A well-known Bitcoin news website Bitcoin Magazine recently tweeted an image of El Salvador's future Bitcoin City. El Salvador's future #Bitcoin City 🇸🇻 pic.twitter.com/lYV8Tx68ng —...",
    source_name: 'TCU',
    date: 'Tue, 10 May 2022 17:44:32 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://bitcoinmagazine.com/markets/why-the-bitcoin-price-doesnt-matter',
    image_url:
      'https://crypto.snapi.dev/images/v1/r/m/bitcoin-price-and-bitcoin-mining-asic-139096.png',
    title: "The Sky Is Not Falling: Why The Bitcoin Price Doesn't Matter",
    text: "The recent bitcoin price action demonstrates that it's time for a more grown-up culture of building, development and adoption around Bitcoin.",
    source_name: 'Bitcoin Magazine',
    date: 'Tue, 10 May 2022 17:30:00 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://thenewscrypto.com/michael-saylor-remains-positive-on-btc-despite-market-crash/?SNAPI',
    image_url:
      'https://crypto.snapi.dev/images/v1/9/p/michael-saylor-doesnt-think-bitcoin-is-going-to-be-currency-in-139095.jpg',
    title: 'Michael Saylor Remains Positive on BTC Despite Market Crash',
    text: "Michale Saylor urges investors to be confident on BTC.  BTC's current downfall did not affect MicroStrategy drastically.",
    source_name: 'TheNewsCrypto',
    date: 'Tue, 10 May 2022 17:20:22 -0400',
    topics: [],
    sentiment: 'Positive',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://investorplace.com/2022/05/what-the-bleep-is-happening-with-terra-ust-and-bitcoin/',
    image_url:
      'https://crypto.snapi.dev/images/v1/o/k/4rfff23-138628-139090.jpg',
    title: 'What the Bleep is Happening With Terra, UST, and Bitcoin',
    text: "Just when it looked like Bitcoin (BTC-USD) was going sideways for a while – it crashed. Let's take a look at the domino effect here – and where we go next!",
    source_name: 'Investorplace',
    date: 'Tue, 10 May 2022 17:10:42 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Article',
    tickers: ['BTC', 'LUNA', 'UST'],
  },
  {
    news_url: 'https://www.youtube.com/watch?v=huuzVxz9ua4',
    image_url:
      'https://crypto.snapi.dev/images/v1/a/8/lunas-bitcoin-liquidation-marks-significant-reversal-castle-island-ventures-nic-carter-139084.jpg',
    title:
      "Luna's bitcoin liquidation marks significant reversal: Castle Island Ventures' Nic Carter",
    text: 'Nic Carter, Castle Island Ventures partner and co-founder of Coin Metrics, reacts to the news that the Luna Foundation Guard liquidated its bitcoin reserves to protect the price of its stablecoin. For access to live and exclusive video from CNBC subscribe to CNBC PRO: https://cnb.cx/2NGeIvi  » Subscribe to CNBC TV: https://cnb.cx/SubscribeCNBCtelevision » Subscribe to CNBC: https://cnb.cx/SubscribeCNBC Turn to CNBC TV for the latest stock market news and analysis.',
    source_name: 'CNBC Television',
    date: 'Tue, 10 May 2022 17:01:23 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Video',
    tickers: ['BTC', 'LUNA'],
  },
  {
    news_url: 'https://crypto.news/australia-launch-bitcoin-etf/',
    image_url:
      'https://crypto.snapi.dev/images/v1/e/f/australia-and-crypto-139083.jpg',
    title: 'Australia Set to Finally Launch Bitcoin ETF This Week',
    text: 'After an initial setback last month, Australia is set to release its crypto exchange-traded funds (ETFs). The set of ETFs formally slated to launch last month will be listed on the Cboe exchange this Thursday to track the performance of…',
    source_name: 'Crypto news',
    date: 'Tue, 10 May 2022 17:00:00 -0400',
    topics: [],
    sentiment: 'Positive',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url: 'https://www.youtube.com/watch?v=5AnMlqiF4cI',
    image_url:
      'https://crypto.snapi.dev/images/v1/k/h/genesis-noelle-acheson-on-why-bitcoin-sell-off-is-necessary-for-long-term-holders-139085.jpg',
    title:
      "Genesis' Noelle Acheson on why Bitcoin sell-off is 'necessary' for long-term holders",
    text: 'Genesis head of market insights Noelle Acheson discusses her market outlook, the bitcoin capitulation phase, and why the sell-off is "necessary" for long-term holders to take control of bitcoin\'s price.',
    source_name: 'CNBC Television',
    date: 'Tue, 10 May 2022 16:57:37 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Video',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://cryptopotato.com/townsquare-media-purchased-5-million-in-bitcoin-during-q1-2022/',
    image_url:
      'https://crypto.snapi.dev/images/v1/h/g/bitcoin-blocks-cover-139080.jpg',
    title: 'Townsquare Media Purchased $5 Million in Bitcoin During Q1 2022',
    text: 'The digital media is interested in Bitcoin for the long term but is currently down on its investment. ',
    source_name: 'CryptoPotato',
    date: 'Tue, 10 May 2022 16:52:17 -0400',
    topics: [],
    sentiment: 'Positive',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://www.newsbtc.com/news/bitcoin/mercury-retrograde-2022-mood-shift-bitcoin/',
    image_url:
      'https://crypto.snapi.dev/images/v1/i/s/istock-1076889856-139079.jpg',
    title: 'Will Mercury In Retrograde Bring A Mood Shift In Bitcoin?',
    text: 'Bitcoin is in free fall, just as Mercury goes retrograde for the second time in 2022. The astrological event is blamed for all kinds of miscommunications and technology-related issues.',
    source_name: 'NewsBTC',
    date: 'Tue, 10 May 2022 16:35:15 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://bitcoinmagazine.com/culture/bitcoin-is-venice-sustaining-unsustainable',
    image_url:
      'https://crypto.snapi.dev/images/v1/c/m/20220509-btcfinal-bitcoinvenicep9-139076.png',
    title: 'Bitcoin Is Venice: Sustaining The Unsustainable',
    text: 'Bitcoin can repair the broken incentive systems created by finance and low interest rates that lead to unsustainable practices.',
    source_name: 'Bitcoin Magazine',
    date: 'Tue, 10 May 2022 16:30:00 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url: 'https://bitcoinmagazine.com/culture/god-is-dead-bitcoin-lives',
    image_url:
      'https://crypto.snapi.dev/images/v1/n/t/btc-hodljpgoptimal-138931-139075.jpg',
    title: 'God Is Dead, Bitcoin Lives',
    text: 'A philosophical discussion based on ideas popularized by Friedrich Nietzsche with Bitcoin as a new-world mythology to replace current beliefs.',
    source_name: 'Bitcoin Magazine',
    date: 'Tue, 10 May 2022 16:30:00 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://decrypt.co/99970/michael-saylor-microstrategy-could-post-other-collateral-bitcoin-crashes',
    image_url:
      'https://crypto.snapi.dev/images/v1/c/h/image-2022-05-10-150433020-138772-139078.png',
    title:
      "Michael Saylor Says MicroStrategy Could Post ‘Other Collateral' if Bitcoin Crashes",
    text: "The Bitcoin believer doesn't appear to be selling his company's stockpile anytime soon.",
    source_name: 'Decrypt',
    date: 'Tue, 10 May 2022 16:29:41 -0400',
    topics: [],
    sentiment: 'Neutral',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url:
      'https://cointelegraph.com/news/altcoins-stage-a-relief-rally-while-bitcoin-traders-decide-whether-to-buy-the-dip',
    image_url:
      'https://crypto.snapi.dev/images/v1/u/c/840-ahr0chm6ly9zmy5jb2ludgvszwdyyxbolmnvbs91cgxvywrzlziwmjitmduvogqwzwrlyzktzgvhoc00ywmxlwjknwitzdhmytu4ytzlmmzmlmpwzw-139070.jpg',
    title:
      'Altcoins stage a relief rally while Bitcoin traders decide whether to buy the dip',
    text: 'Stocks and altcoin prices bounced as the sell-off in BTC took a pause, but analysts continue to warn that further downside could occur shortly.',
    source_name: 'Cointelegraph',
    date: 'Tue, 10 May 2022 16:11:39 -0400',
    topics: [],
    sentiment: 'Positive',
    type: 'Article',
    tickers: ['BTC'],
  },
  {
    news_url: 'https://www.youtube.com/watch?v=SvuteXx3Ldo',
    image_url:
      'https://crypto.snapi.dev/images/v1/7/m/crypto9-138286-138600-139066.jpg',
    title: 'BTC Down Over 10% in 24 Hours, TerraUSD Drops to 92 Cents',
    text: 'TheoTrade Co-founder Don Kaufman shares his bitcoin price analysis as the “digital gold” dropped over 10% during the last 24 hours. How is BTC correlated with the Nasdaq and S&P 500?',
    source_name: 'Coindesk',
    date: 'Tue, 10 May 2022 16:05:51 -0400',
    topics: [],
    sentiment: 'Negative',
    type: 'Video',
    tickers: ['BTC', 'UST'],
  },
]
