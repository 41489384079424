export const NewsData2 = [
  {
    kind: 'news',
    domain: 'bitcoinist.com',
    source: {
      title: 'Bitcoinist',
      region: 'en',
      domain: 'bitcoinist.com',
      path: null,
    },
    title:
      'Madonna Gives Birth To Flora And Fauna In 1st Beeple NFT Project (That\u2019s NSFW)',
    published_at: '2022-05-10T05:31:01Z',
    slug: 'Madonna-Gives-Birth-To-Flora-And-Fauna-In-1st-Beeple-NFT-Project-Thats-NSFW',
    id: 15158685,
    url: 'https://cryptopanic.com/news/15158685/Madonna-Gives-Birth-To-Flora-And-Fauna-In-1st-Beeple-NFT-Project-Thats-NSFW',
    created_at: '2022-05-10T05:31:01Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'cointelegraph.com',
    source: {
      title: 'CoinTelegraph',
      region: 'en',
      domain: 'cointelegraph.com',
      path: null,
    },
    title:
      'Nigeria upgrades CBDC as crypto restrictions cripple fintech industry',
    published_at: '2022-05-10T05:29:22Z',
    slug: 'Nigeria-upgrades-CBDC-as-crypto-restrictions-cripple-fintech-industry',
    id: 15158401,
    url: 'https://cryptopanic.com/news/15158401/Nigeria-upgrades-CBDC-as-crypto-restrictions-cripple-fintech-industry',
    created_at: '2022-05-10T05:29:22Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'theblockcrypto.com',
    source: {
      title: 'The Block',
      region: 'en',
      domain: 'theblockcrypto.com',
      path: null,
    },
    title: 'Binance suspends LUNA and UST withdrawals amid market turbulence',
    published_at: '2022-05-10T05:28:52Z',
    slug: 'Binance-suspends-LUNA-and-UST-withdrawals-amid-market-turbulence',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
      {
        code: 'UST',
        title: 'TerraUSD',
        slug: 'terrausd',
        url: 'https://cryptopanic.com/news/terrausd/',
      },
      {
        code: 'LUNA',
        title: 'Terra',
        slug: 'luna-terra',
        url: 'https://cryptopanic.com/news/luna-terra/',
      },
      {
        code: 'LUNA',
        title: 'Terra',
        slug: 'terra-luna',
        url: 'https://cryptopanic.com/news/terra-luna/',
      },
    ],
    id: 15158435,
    url: 'https://cryptopanic.com/news/15158435/Binance-suspends-LUNA-and-UST-withdrawals-amid-market-turbulence',
    created_at: '2022-05-10T05:28:52Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title: 'TZ APAC, NUS join for blockchain, computing education',
    published_at: '2022-05-10T05:14:00Z',
    slug: 'TZ-APAC-NUS-join-for-blockchain-computing-education',
    id: 15158329,
    url: 'https://cryptopanic.com/news/15158329/TZ-APAC-NUS-join-for-blockchain-computing-education',
    created_at: '2022-05-10T05:14:00Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'beincrypto.com',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 1,
    },
    source: {
      title: 'BeInCrypto',
      region: 'en',
      domain: 'beincrypto.com',
      path: null,
    },
    title:
      'Galaxy Digital Chief Predicts Crypto Mayhem Might Not Be Over \u2014 Here\u2019s Why',
    published_at: '2022-05-10T05:11:14Z',
    slug: 'Galaxy-Digital-Chief-Predicts-Crypto-Mayhem-Might-Not-Be-Over-Heres-Why',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
    ],
    id: 15158326,
    url: 'https://cryptopanic.com/news/15158326/Galaxy-Digital-Chief-Predicts-Crypto-Mayhem-Might-Not-Be-Over-Heres-Why',
    created_at: '2022-05-10T05:11:14Z',
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title: 'India GST Council weighs 28% tax on crypto transactions',
    published_at: '2022-05-10T05:02:19Z',
    slug: 'India-GST-Council-weighs-28-tax-on-crypto-transactions',
    id: 15158306,
    url: 'https://cryptopanic.com/news/15158306/India-GST-Council-weighs-28-tax-on-crypto-transactions',
    created_at: '2022-05-10T05:02:19Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    votes: {
      negative: 1,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 1,
      lol: 1,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title:
      'China broadens digital yuan trial to select hospitals, Tianjin civil salaries',
    published_at: '2022-05-10T04:50:53Z',
    slug: 'China-broadens-digital-yuan-trial-to-select-hospitals-Tianjin-civil-salaries',
    id: 15158281,
    url: 'https://cryptopanic.com/news/15158281/China-broadens-digital-yuan-trial-to-select-hospitals-Tianjin-civil-salaries',
    created_at: '2022-05-10T04:50:53Z',
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title: 'Three Australian crypto ETFs to debut on Thursday after delays',
    published_at: '2022-05-10T04:38:20Z',
    slug: 'Three-Australian-crypto-ETFs-to-debut-on-Thursday-after-delays',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
      {
        code: 'ETH',
        title: 'Ethereum',
        slug: 'ethereum',
        url: 'https://cryptopanic.com/news/ethereum/',
      },
    ],
    id: 15158254,
    url: 'https://cryptopanic.com/news/15158254/Three-Australian-crypto-ETFs-to-debut-on-Thursday-after-delays',
    created_at: '2022-05-10T04:38:20Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'beincrypto.com',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 1,
    },
    source: {
      title: 'BeInCrypto',
      region: 'en',
      domain: 'beincrypto.com',
      path: null,
    },
    title: 'Metaverse Land Prices Are Falling: Is There A Buying Opportunity?',
    published_at: '2022-05-10T04:37:22Z',
    slug: 'Metaverse-Land-Prices-Are-Falling-Is-There-A-Buying-Opportunity',
    id: 15158248,
    url: 'https://cryptopanic.com/news/15158248/Metaverse-Land-Prices-Are-Falling-Is-There-A-Buying-Opportunity',
    created_at: '2022-05-10T04:37:22Z',
  },
  {
    kind: 'news',
    domain: 'cointelegraph.com',
    votes: {
      negative: 0,
      positive: 0,
      important: 1,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
    source: {
      title: 'CoinTelegraph',
      region: 'en',
      domain: 'cointelegraph.com',
      path: null,
    },
    title:
      'Bitcoin network transactions and fees surge amid investor de-risking',
    published_at: '2022-05-10T04:24:15Z',
    slug: 'Bitcoin-network-transactions-and-fees-surge-amid-investor-de-risking',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
    ],
    id: 15158234,
    url: 'https://cryptopanic.com/news/15158234/Bitcoin-network-transactions-and-fees-surge-amid-investor-de-risking',
    created_at: '2022-05-10T04:24:15Z',
  },
  {
    kind: 'media',
    domain: 'youtube.com',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 2,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
    source: {
      title: 'The Crypto Lark',
      region: 'en',
      domain: 'youtube.com',
      path: null,
    },
    title: 'Bitcoin Crashes, What Happened? Is It Over?',
    published_at: '2022-05-10T04:17:16Z',
    slug: 'Bitcoin-Crashes-What-Happened-Is-It-Over',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
    ],
    id: 15158242,
    url: 'https://cryptopanic.com/news/15158242/Bitcoin-Crashes-What-Happened-Is-It-Over',
    created_at: '2022-05-10T04:17:16Z',
  },
  {
    kind: 'news',
    domain: 'cryptoninjas.net',
    source: {
      title: 'CryptoNinjas',
      region: 'en',
      domain: 'cryptoninjas.net',
      path: null,
    },
    title:
      'Tezos Asia entity TZ APAC teams up with NUS Computing to develop blockchain talent',
    published_at: '2022-05-10T03:54:04Z',
    slug: 'Tezos-Asia-entity-TZ-APAC-teams-up-with-NUS-Computing-to-develop-blockchain-talent',
    currencies: [
      {
        code: 'XTZ',
        title: 'Tezos',
        slug: 'tezos',
        url: 'https://cryptopanic.com/news/tezos/',
      },
    ],
    id: 15158196,
    url: 'https://cryptopanic.com/news/15158196/Tezos-Asia-entity-TZ-APAC-teams-up-with-NUS-Computing-to-develop-blockchain-talent',
    created_at: '2022-05-10T03:54:04Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'bitcoinist.com',
    source: {
      title: 'Bitcoinist',
      region: 'en',
      domain: 'bitcoinist.com',
      path: null,
    },
    title:
      'The future of travel is here: Next Earth announces partnership with Iomob and Vueling Airlines',
    published_at: '2022-05-10T03:38:05Z',
    slug: 'The-future-of-travel-is-here-Next-Earth-announces-partnership-with-Iomob-and-Vueling-Airlines',
    id: 15158155,
    url: 'https://cryptopanic.com/news/15158155/The-future-of-travel-is-here-Next-Earth-announces-partnership-with-Iomob-and-Vueling-Airlines',
    created_at: '2022-05-10T03:38:05Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'ambcrypto.com',
    votes: {
      negative: 0,
      positive: 3,
      important: 2,
      liked: 3,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
    source: {
      title: 'AMBCrypto',
      region: 'en',
      domain: 'ambcrypto.com',
      path: null,
    },
    title: 'Important buy-the-dip levels for Ethereum price you should know',
    published_at: '2022-05-10T03:30:03Z',
    slug: 'Important-buy-the-dip-levels-for-Ethereum-price-you-should-know',
    currencies: [
      {
        code: 'ETH',
        title: 'Ethereum',
        slug: 'ethereum',
        url: 'https://cryptopanic.com/news/ethereum/',
      },
    ],
    id: 15158138,
    url: 'https://cryptopanic.com/news/15158138/Important-buy-the-dip-levels-for-Ethereum-price-you-should-know',
    created_at: '2022-05-10T03:30:03Z',
  },

  {
    kind: 'news',
    domain: 'forkast.news',
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title: 'Coinhako receives full license in Singapore',
    published_at: '2022-05-10T03:10:25Z',
    slug: 'Coinhako-receives-full-license-in-Singapore',
    id: 15158129,
    url: 'https://cryptopanic.com/news/15158129/Coinhako-receives-full-license-in-Singapore',
    created_at: '2022-05-10T03:10:25Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'media',
    domain: 'youtube.com',
    source: {
      title: 'Real-Crypto',
      region: 'en',
      domain: 'youtube.com',
      path: null,
    },
    title: 'EXTREME STREAM Will 30k hold?',
    published_at: '2022-05-10T03:09:29Z',
    slug: 'EXTREME-STREAM-Will-30k-hold',
    id: 15157919,
    url: 'https://cryptopanic.com/news/15157919/EXTREME-STREAM-Will-30k-hold',
    created_at: '2022-05-10T03:09:29Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title:
      'How tokenization could transform Hong Kong\u2019s fixed income markets',
    published_at: '2022-05-10T03:02:00Z',
    slug: 'How-tokenization-could-transform-Hong-Kongs-fixed-income-markets',
    id: 15158114,
    url: 'https://cryptopanic.com/news/15158114/How-tokenization-could-transform-Hong-Kongs-fixed-income-markets',
    created_at: '2022-05-10T03:02:00Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'cointelegraph.com',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 1,
      comments: 0,
    },
    source: {
      title: 'CoinTelegraph',
      region: 'en',
      domain: 'cointelegraph.com',
      path: null,
    },
    title:
      'More than a third of AMC online payments are crypto or digital: CEO',
    published_at: '2022-05-10T03:01:28Z',
    slug: 'More-than-a-third-of-AMC-online-payments-are-crypto-or-digital-CEO',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
      {
        code: 'DOGE',
        title: 'Dogecoin',
        slug: 'dogecoin',
        url: 'https://cryptopanic.com/news/dogecoin/',
      },
    ],
    id: 15158109,
    url: 'https://cryptopanic.com/news/15158109/More-than-a-third-of-AMC-online-payments-are-crypto-or-digital-CEO',
    created_at: '2022-05-10T03:01:28Z',
  },
  {
    kind: 'news',
    domain: 'bravenewcoin.com',
    source: {
      title: 'Brave New Coin',
      region: 'en',
      domain: 'bravenewcoin.com',
      path: null,
    },
    title: 'IBEX - Taking Bitcoin to the World',
    published_at: '2022-05-10T03:00:00Z',
    slug: 'IBEX-Taking-Bitcoin-to-the-World',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
    ],
    id: 15158233,
    url: 'https://cryptopanic.com/news/15158233/IBEX-Taking-Bitcoin-to-the-World',
    created_at: '2022-05-10T03:00:00Z',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 0,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
  },
  {
    kind: 'news',
    domain: 'forkast.news',
    votes: {
      negative: 0,
      positive: 0,
      important: 0,
      liked: 1,
      disliked: 0,
      lol: 0,
      toxic: 0,
      saved: 0,
      comments: 0,
    },
    source: {
      title: 'Forkast.News',
      region: 'en',
      domain: 'forkast.news',
      path: null,
    },
    title: 'El Salvador snaps up US$15 mln in Bitcoin after price plunge',
    published_at: '2022-05-10T02:56:51Z',
    slug: 'El-Salvador-snaps-up-US15-mln-in-Bitcoin-after-price-plunge',
    currencies: [
      {
        code: 'BTC',
        title: 'Bitcoin',
        slug: 'bitcoin',
        url: 'https://cryptopanic.com/news/bitcoin/',
      },
    ],
    id: 15158093,
    url: 'https://cryptopanic.com/news/15158093/El-Salvador-snaps-up-US15-mln-in-Bitcoin-after-price-plunge',
    created_at: '2022-05-10T02:56:51Z',
  },
]
