import './App.css'
import GlobalStyle from './globalStyles'
import News from './Components/Community/News'
import APITest from './Components/APITester'
import Home from './Pages'
import NewsApiTest from './Components/APITester/NewsApiTest'
import TestT from './Components/APITester/TestT'
import NewsTest from './Components/APITester/NewsTest'
import VideoTest from './Components/APITester/VideoTest'
import Press from './Components/Community/Press'
import CopyMainTest from './Components/APITester/CopyMainTest'

function App() {
  return (
    <div className='App'>
      <GlobalStyle />
      {/* <CopyMainTest /> */}
      {/* <Press /> */}
      {/* <TestT /> */}
      <Home />
      {/* <News /> */}
      {/* <NewsTest /> */}
      {/* <VideoTest /> */}
    </div>
  )
}

export default App
