import React, { useState } from 'react'
import Navbar from '../../Navbar'
import Sidebar from '../../Sidebar'
import { InfoContainer, InfoWrapper, SectionTitle } from '../Team/TeamElements'

const Relationship = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Navbar toggle={toggle} info='Discover' />
      <Sidebar isOpen={isOpen} toggle={toggle} info='Discover' />
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Relationship</SectionTitle>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Relationship
