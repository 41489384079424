import React from 'react'
import Registration from '../Components/Registration'

const RegistrationPage = () => {
  return (
    <>
      <Registration />
    </>
  )
}

export default RegistrationPage
