import React, { useRef } from 'react'
import styled from 'styled-components'
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import emailjs from '@emailjs/browser'

const ContactContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin: auto;
  margin-top: 50px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-top: 50px;
  }
`
const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  /* @media screen and (max-width: 780px) {
    height: 700px;
  } */
`
const ContactRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  justify-content: center;
  align-items: center;
  grid-template-areas: 'col1 col2';
  @media screen and (max-width: 486px) {
    grid-template-areas: 'col1' 'col2';
  }
`
const Column1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  padding-top: 0;
  @media screen and (max-width: 815px) {
    max-width: 500px;
    justify-content: center;
  }
`
const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 58px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 780px) {
    font-size: 32px;
  }
`
const SubHeading = styled.p`
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 400;
  color: var(--primaryColor);
  @media screen and (max-width: 780px) {
    font-size: 22px;
  }
`
const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px;
`
const ImgWrap = styled.div`
  height: 100%;
  width: 20%;
`
const Img1 = styled(FaPhone)`
  height: 50%;
  width: 50%;
`
const Img2 = styled(FaEnvelope)`
  height: 50%;
  width: 50%;
`
const ContactDetail = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 780px) {
    font-size: 14px;
    line-height: 18px;
  }
`
const Detail = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  @media screen and (max-width: 815px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const Column2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  background: #c8c8ed;
  border-radius: 10px;
`
const FormContainer = styled.div`
  padding: 10px 15px;
  display: block;
  width: 100%;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`
const FormItems = styled.div`
  display: flex;
  flex-direction: row;
`
const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`
const LabelA = styled.p`
  position: relative;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s ease;
  color: #666;
`
const InputName = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border: 2px solid #333;
  }
`
const InputEmail = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border-bottom: 2px solid #333;
  }
`
const InputCountry = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border: 2px solid #333;
  }
`
const InputSubject = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border-bottom: 2px solid #333;
  }
`
const InputMessage = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  resize: none;
`
const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
`
const BtnSendMsg = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 55px;
  width: 342px;
  height: 52px;
  background: #352e68;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #fff;
`

const CopyMainTest = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_dikz8uj',
        'template_spus4kk',
        form.current,
        'MgDhCN83jAuqyUwFb'
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log('message sent')
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <>
      <ContactContainer>
        <ContactWrapper>
          <ContactRow>
            <Column1>
              <TextWrapper>
                <Heading>Did we miss something?</Heading>
                <SubHeading>Contact Us</SubHeading>
                <ContactDetails>
                  <DetailsItem>
                    <ImgWrap>
                      <Img2 />
                    </ImgWrap>
                    <ContactDetail>
                      <Title>Email</Title>
                      <Detail>admin@thecryptoplayground.com</Detail>
                    </ContactDetail>
                  </DetailsItem>
                </ContactDetails>
              </TextWrapper>
            </Column1>
            <Column2>
              <FormContainer>
                <Form ref={form} onSubmit={sendEmail}>
                  <FormItems>
                    <FormItem>
                      <LabelA type='text'>Name</LabelA>
                      <InputName name='user_name' />
                    </FormItem>
                    <FormItem>
                      <LabelA type='email'>Email</LabelA>
                      <InputEmail name='user_email' />
                    </FormItem>
                    <FormItem>
                      <LabelA type='text'>Country</LabelA>
                      <InputCountry name='user_country' />
                    </FormItem>
                  </FormItems>

                  <LabelA type='text'>Subject</LabelA>
                  <InputSubject name='user_subject' />
                  <LabelA type='text'>Message</LabelA>
                  <InputMessage name='user_message' />
                  <BtnWrap>
                    <BtnSendMsg type='submit'>Send Message</BtnSendMsg>
                  </BtnWrap>
                </Form>
              </FormContainer>
            </Column2>
          </ContactRow>
        </ContactWrapper>
      </ContactContainer>
    </>
  )
}

export default CopyMainTest
