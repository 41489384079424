import styled from 'styled-components'

export const ServicesContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  /* margin: auto 0; */
  @media screen and (max-width: 460px) {
    height: 2170px;
    margin-top: -160px;
    /* margin-top: 230px; */
  }
`

export const ServicesWrapper = styled.div`
  width: 1350px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  align-items: center;
  grid-gap: 16px;
  /* padding: 0 20px; */

  @media screen and (max-width: 815px) {
    /* margin-top: 200px; */
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
    /* padding: 0 20px; */
  }
`

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 460px) {
    margin-top: 10px;
    max-height: 380px;
  }
`

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`
export const ServicesTitle = styled.h1`
  color: #352e68;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 10px;
`
export const ServicesDescription = styled.h1`
  color: #352e68;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`
export const ServicesDetails = styled.div`
  margin-top: 25px;
  margin: 25px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 60px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: white;
  @media screen and (max-width: 460px) {
    /* width: 300px; */
    grid-template-columns: 1fr;
  }
`
export const ServicesHeading = styled.h1`
  font-weight: 800;
  font-size: 72px;
  color: #352e68;
  margin-top: 50px;
  @media screen and (max-width: 1000px) {
    font-size: 40px;
  }
  @media screen and (max-width: 460px) {
    font-size: 32px;
    margin-top: 50px;
  }
`
export const ServicesSub = styled.h1`
  margin-left: 50px;
  padding-top: 20px;
  font-weight: 500;
  font-size: 24px;
  color: #352e68;
  margin-top: 50px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
    margin-left: 20px;
  }
  @media screen and (max-width: 460px) {
    margin-top: 0px;
    font-size: 18px;
  }
`
