import React, { useEffect } from 'react'
import { ButtonType2 } from '../assets/buttons/Buttons'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  Heading,
  Description,
  DescriptionHead,
  DescriptionImageWrapper,
  ImageContent,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
} from './InfoSection2Elements'

// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const InfoSection2 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow imgStart={true}>
            <Column1 data-aos='fade-left'>
              <TextWrapper>
                <Heading>Immerse Yourself</Heading>
                <Description>Into the world’s first</Description>
                <Description>virtual crypto</Description>{' '}
                <Description> education metaverse</Description>
                <DescriptionHead>Powered by:</DescriptionHead>
              </TextWrapper>
              <DescriptionImageWrapper>
                <ImageContent src={require('../assets/images/unity.png')} />
                <ImageContent src={require('../assets/images/logo3.jpg')} />
              </DescriptionImageWrapper>

              <BtnWrap>
                <a href='https://thecryptoplayground.com/'>
                  <ButtonType2>Find Out More</ButtonType2>
                </a>
              </BtnWrap>
            </Column1>
            <Column2 data-aos='fade-right'>
              <ImgWrap>
                <Img src={require('../assets/images/info2.png')} alt='' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection2
