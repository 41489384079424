import styled from 'styled-components'

export const InfoContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin-top: 50px;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 460px) {
    height: 900px;
  }
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 300px;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  @media screen and (max-width: 768px) {
    /* margin-top: -400px; */
  }
  @media screen and (max-width: 460px) {
    padding: 20px;
  }
`
export const InfoRow = styled.div`
  margin-left: 100px;
  width: 100%;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 815px) {
    margin-left: 0;
    grid-template-areas: 'col1' 'col2';
    /* grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`}; */
  }
`
export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  grid-area: col1;
  justify-content: center;
  align-items: center;
`
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 22px;
    line-height: 1;
    font-weight: 600;
  }
`
export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  @media screen and (max-width: 460px) {
    max-width: 350px;
  }
`
export const LabelName = styled.input`
  padding: 14px 16px;
  margin: 10px;
  position: static;
  width: 200px;
  height: 52px;
  left: 0px;
  top: 0px;
  background: #c8c8ed;
  border: 1px solid #352e68;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  @media screen and (max-width: 460px) {
    width: 150px;
    height: 42px;
  }
`
export const LabelCountry = styled.input`
  margin: 10px;
  padding: 14px 16px;
  position: static;
  width: 200px;
  height: 52px;
  left: 0px;
  top: 0px;
  background: #c8c8ed;
  border: 1px solid #352e68;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  @media screen and (max-width: 460px) {
    width: 150px;
    height: 42px;
  }
`
export const TryDiv = styled.div`
  margin: 10px;
  width: 480px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 460px) {
    width: 350px;
  }
`
export const LabelEmail = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  width: 300px;
  height: 52px;

  background: #c8c8ed;
  border: 1px solid #352e68;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  width: 50%;
  @media screen and (max-width: 460px) {
    width: 190px;
    height: 42px;
    width: 50%;
  }
`
export const BtnWrap = styled.div`
  margin-left: 20px;
  display: flex;
  @media screen and (max-width: 460px) {
    margin-left: 16px;
  }
`
export const JoinBtn = styled.button`
  padding: 10px;
  background: var(--primaryColor);
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  @media screen and (max-width: 460px) {
    font-size: 14px;
  }
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 815px) {
    display: none;
    /* margin-left: 100px; */
  }
`
export const LogoContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  @media screen and (max-width: 815px) {
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* margin-left: 100px; */
  }
`
export const LogoImage = styled.img`
  margin: 0 50px;
  height: 100%;
  width: 250px;
  @media screen and (max-width: 768px) {
    margin: 20px;
    /* margin-left: 100px; */
  }
`
