import React, { useEffect } from 'react'
import { ButtonType1 } from '../assets/buttons/Buttons'

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  Heading,
  Subtitle,
  Column2,
  ImgWrap,
  Img,
  SocialWrap,
  Alink,
  SocialImage,
} from './SocialElements'

// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const Social = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <InfoContainer id='id_social'>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <Heading>Join The </Heading>
                <Heading>Conversation</Heading>
                <Subtitle>
                  Get involved by joining one of our community channels!
                </Subtitle>
                <SocialWrap data-aos='flip-up'>
                  <Alink href='https://t.me/+UC6DnDEvLGc8EJ5b'>
                    <SocialImage
                      src={require('../assets/images/telegram.png')}
                      alt=''
                    />
                  </Alink>
                  <Alink href='https://twitter.com/TheCryptoPlayg1'>
                    <SocialImage
                      src={require('../assets/images/discord.png')}
                      alt=''
                    />
                  </Alink>
                  <Alink href='https://twitter.com/TheCryptoPlayg1'>
                    <SocialImage
                      src={require('../assets/images/twitter.png')}
                      alt=''
                    />
                  </Alink>
                  <Alink href='https://www.youtube.com/channel/UCu_8DNxdEDdVbnMOpxl58uw/featured'>
                    <SocialImage
                      src={require('../assets/images/youtube.png')}
                      alt=''
                    />
                  </Alink>
                </SocialWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img
                  data-aos='fade-left'
                  src={require('../assets/images/social.png')}
                  alt=''
                />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Social
