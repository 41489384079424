import styled from 'styled-components'

export const InfoContainer = styled.div`
  /* background: var(--secondaryBg); */
  border-radius: 10px;
  /* margin-top: 80px; */
  margin-bottom: 80px;
  overflow: hidden;
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    /* padding: 100px 0; */
    /* margin-top: 100px; */
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  justify-content: center;
  align-items: center;
`

export const SectionTitle = styled.h1`
  margin-top: 60px;
  font-size: 58px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 780px) {
    font-size: 32px;
  }
`
export const HeadingOne = styled.h1`
  color: var(--primaryColor);
  font-size: 36px;
  line-height: 3em;
  @media screen and (max-width: 460px) {
    font-size: 24px;
    line-height: 2em;
  }
`
export const InfoRow2 = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: 1350px;
  margin: auto;
  margin-top: 50px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
`
export const InfoRow2Wrapper = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-columns: minmax(1fr);
  grid-template-areas: 'col1 col2 col3 col4';

  @media screen and (max-width: 815px) {
    grid-template-areas: 'col1 col2' 'col3 col4';
  }
  @media screen and (max-width: 460px) {
    grid-template-areas: 'col1' 'col2' 'col3' 'col4';
  }
`
export const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 815px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 460px) {
    margin-left: 30px;
    margin-top: 20px;
  }
`
export const RoadMapImg = styled.img`
  width: 150px;
  height: 150px;
`
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 460px) {
  }
`
export const DetailTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
`
export const DetailsP = styled.p``
export const Col1 = styled.div`
  width: 300px;
  /* width: 100%; */
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`
export const Col2 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`
export const Col3 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col3;
`
export const Col4 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col4;
`
