import React, { useEffect } from 'react'
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesTitle,
  ServicesDescription,
  ServicesDetails,
  ServicesHeading,
  ServicesSub,
} from './ServiceElements'
// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const Services = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <ServicesContainer id='id_journeys'>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon
              data-aos='fade-left'
              src={require('../assets/images/invest.png')}
            />
            <ServicesTitle data-aos='fade-right'>Invest</ServicesTitle>
            <ServicesDescription data-aos='fade-right'>
              Build or enhance your skills learning to trade
            </ServicesDescription>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              data-aos='fade-left'
              src={require('../assets/images/explore.png')}
            />

            <ServicesTitle data-aos='fade-right'>Explore</ServicesTitle>
            <ServicesDescription data-aos='fade-right'>
              Jump in and learn the basics of the world of crypto
            </ServicesDescription>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              data-aos='fade-left'
              src={require('../assets/images/adopt.png')}
            />

            <ServicesTitle data-aos='fade-right'>Adopt</ServicesTitle>
            <ServicesDescription data-aos='fade-right'>
              Swing into using crypto for everyday living
            </ServicesDescription>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              data-aos='fade-left'
              src={require('../assets/images/create.png')}
            />

            <ServicesTitle data-aos='fade-right'>Create</ServicesTitle>
            <ServicesDescription data-aos='fade-right'>
              Have fun creating, buying, selling and trading NFTs
            </ServicesDescription>
          </ServicesCard>
        </ServicesWrapper>
        <ServicesDetails data-aos='fade-right'>
          <ServicesHeading>Become a Pro!</ServicesHeading>
          <ServicesSub>
            Learn and gain confidence by practicing in a fully simulated
            environment
          </ServicesSub>
        </ServicesDetails>
      </ServicesContainer>
    </>
  )
}

export default Services
