import styled from 'styled-components'
import { FaPlus, FaMinus, FaCaretRight } from 'react-icons/fa'

export const InfoContainer = styled.div`
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  /* margin-top: -50px; */
  @media screen and (max-width: 460px) {
    margin-top: 50px;
  }
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
`
export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
`
export const InfoRow1 = styled.div`
  background: var(--primaryBg);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 0.7fr 2fr 0.7fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'col1 col2 col3';
`
export const Column1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: col1;
`
export const Column2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: col2;
`
export const Heading = styled.h1`
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  @media screen and (max-width: 780px) {
    font-size: 45px;
    margin-left: 30px;
  }
  @media screen and (max-width: 460px) {
    font-size: 32px;
    margin-left: 50px;
    line-height: 1.8em;
  }
`
export const Column3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: col3;
`
export const Img = styled.img`
  width: 150px;
  @media screen and (max-width: 780px) {
    width: 80px;
  }
`

export const InfoRow2 = styled.div`
  background: var(--primaryBg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: grid;
  justify-content: center;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: 'col4 col5';

  @media screen and (max-width: 460px) {
    grid-template-areas: 'col4' 'col5';
  }
`
export const Column4 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col4;
`
export const QuestionContainer = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
  cursor: pointer;
`

export const QuestionItems = styled.div`
  width: 100%;
  padding: 10px 20px;
`

export const Questions = styled.a`
  border-bottom: 1px solid white;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #85662b;
  cursor: pointer;
`
export const Question = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: left;
`
export const QuestionDiv = styled.div`
  margin-top: 11px;
`
export const QuestionTitle = styled.h3`
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 2em;
  color: #352e68;
  /* justify-content: left; */
`
export const ArrowHead = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  width: 10%;
  margin-top: 11px;
`
export const FaArrowDown1 = styled(FaPlus)`
  display: block;
`
export const FaCaretRight1 = styled(FaCaretRight)`
  color: white;
  display: block;
`
export const FaArrowUp1 = styled(FaMinus)`
  display: block;
`
export const Answers = styled.div`
  border-bottom: 1px solid white;
  padding: 0 20px;
  /* max-height: 0; */
`
export const Answer = styled.p`
  padding-left: 1.2em;
  height: auto;
  border-bottom: 1px solid white;
  font-size: 16px;
  line-height: 1.6875rem;
`
export const Column5 = styled.div`
  grid: col5;
  margin-bottom: 15px;
  padding: 0 15px;
`
