import styled from 'styled-components'

export const InfoContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  padding-top: 100px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 815px) {
    /* height: 1200px; */
  }
  @media screen and (max-width: 460px) {
    padding-top: 30px;
    height: 830px;
    margin-top: -80px;
    /* margin-top: 60px; */
  }
`

export const InfoWrapper = styled.div`
  background: #b3dbdb;
  border-radius: 50px;
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: 800px;
  }
  @media screen and (max-width: 460px) {
    padding: 60px;
    height: 800px;
  }
`

export const InfoRow = styled.div`
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col2 col1';
  /*grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`}; */
  @media screen and (max-width: 460px) {
    grid-template-areas: 'col1' 'col2';
    /* grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`}; */
  }
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  @media screen and (max-width: 460px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  padding: 50px;
  @media screen and (max-width: 815px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 370px;
    padding: 20px;
  }
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 60px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.h1`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 32px;
  line-height: 24px;
  color: var(--tertiaryColor);
  @media screen and (max-width: 815px) {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 31px;

    /* or 172% */
    text-align: center;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  @media screen and (max-width: 780px) {
    margin-top: -50px;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

export const SocialWrap = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  @media screen and (max-width: 460px) {
    height: 100px;
    justify-content: space-around;
  }
`
export const Alink = styled.a`
  margin: 20px;
  width: 100px;
  justify-content: center;
  display: flex;
  padding: 20px;
  height: 160px;
  @media screen and (max-width: 815px) {
    height: 100px;
    justify-content: space-around;
    margin: 0;
  }
  @media screen and (max-width: 460px) {
    height: 110px;
  }
`
export const SocialImage = styled.img`
  &:hover {
    transform: scale(1.2);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }
`
