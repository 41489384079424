import React from 'react'
import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
  InfoRow2,
  HeadingOne,
  InfoRow2Wrapper,
  Col1,
  RoadmapWrapper,
  RoadMapImg,
  Details,
  DetailTitle,
  DetailsP,
  Col2,
  Col3,
  Col4,
} from './RoadmapElements'

const Roadmap = () => {
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Roadmap</SectionTitle>
          <InfoRow2>
            {/* <HeadingOne>Roadmap</HeadingOne> */}
            <InfoRow2Wrapper>
              <Col1>
                <RoadmapWrapper>
                  <RoadMapImg
                    src={require('../../Components/assets/images/target.gif')}
                  />
                  <Details>
                    <DetailTitle>Q4 2021</DetailTitle>

                    <DetailsP>Market Research</DetailsP>
                    <DetailsP>Core Product Selection</DetailsP>
                    <DetailsP>Learning Journeys Mapped</DetailsP>
                    <DetailsP>UI/UX Development Start</DetailsP>
                    <DetailsP>White Paper Development</DetailsP>
                    <DetailsP>Foundational business</DetailsP>
                    <DetailsP> Materials Created</DetailsP>
                  </Details>
                </RoadmapWrapper>
              </Col1>
              <Col2>
                <RoadmapWrapper>
                  <RoadMapImg
                    src={require('../../Components/assets/images/idea.gif')}
                    alt=''
                  />
                  <Details>
                    <DetailTitle>Q1-2 2022</DetailTitle>
                    <DetailsP>UI/UX Development</DetailsP>
                    <DetailsP>Tokenomics</DetailsP>
                    <DetailsP>Website Launch</DetailsP>
                    <DetailsP>Whitepaper Release</DetailsP>
                    <DetailsP>Virtual Land Acquisition</DetailsP>
                  </Details>
                </RoadmapWrapper>
              </Col2>
              <Col3>
                <RoadmapWrapper>
                  <RoadMapImg
                    src={require('../../Components/assets/images/money-bag.gif')}
                  />
                  <Details>
                    <DetailTitle>Q2-3 2022</DetailTitle>
                    <DetailsP>Core Team Selection</DetailsP>
                    <DetailsP>Community Platform</DetailsP>
                    <DetailsP>Marketing</DetailsP>
                    <DetailsP>Seeking Investment</DetailsP>
                  </Details>
                </RoadmapWrapper>
              </Col3>
              <Col4>
                <RoadmapWrapper>
                  <RoadMapImg
                    src={require('../../Components/assets/images/protection.gif')}
                  />
                  <Details>
                    <DetailTitle>Q4 2022</DetailTitle>
                    <DetailsP>MVP 1 Launch</DetailsP>
                    <DetailsP>Marketing Continues</DetailsP>
                    {/* <DetailsP>Development Continue</DetailsP> */}
                    <DetailsP>NFT Development</DetailsP>
                    <DetailsP>IDO Sale</DetailsP>
                    <DetailsP>Supported Wallet Launch</DetailsP>
                  </Details>
                </RoadmapWrapper>
              </Col4>
            </InfoRow2Wrapper>
          </InfoRow2>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Roadmap
