import React, { useEffect } from 'react'
import { ButtonType2 } from '../assets/buttons/Buttons'
import {
  InfoContainer,
  InfoWrapper,
  InfoContent,
  Column1,
  Heading,
  Description,
  Column2,
  TextWrapper,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from './InfoSection1Elements'

// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const InfoSection1 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <InfoContainer id='id_discover' data-aos='zoom-in-down'>
        <InfoWrapper>
          <InfoContent>
            <Column1>
              <Heading>Shaping the Future</Heading>
              <Description>
                Investing in our community is at the core of The Crypto
                Playground.
              </Description>
              <Description>
                Governance will dictate the direction by which the platform acts
                on behalf of its community of users
              </Description>
              <Description>
                Social tokens and rewards will be offered to those who
                contribute and you will have a range of communication options to
                share within and outside of the platform.
              </Description>
            </Column1>
            <Column2>
              <TextWrapper>
                <Subtitle>
                  Our success begins and ends with you!
                  <br /> A community built and governed by its users.
                </Subtitle>
                <BtnWrap>
                  <a href='https://thecryptoplayground.com/'>
                    <ButtonType2>Find Out More</ButtonType2>
                  </a>
                </BtnWrap>
              </TextWrapper>
              <ImgWrap>
                <Img src={require('../assets/images/info.png')} alt='' />
              </ImgWrap>
            </Column2>
          </InfoContent>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection1
