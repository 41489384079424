import styled from 'styled-components'

export const InfoContainer = styled.div`
  max-width: 1350px;
  padding-top: 100px;
  margin-right: auto;
  margin-left: auto;
  height: fit-content;
  overflow: hidden;
  @media screen and (max-width: 815px) {
    padding: 50px 0;
  }
`

export const InfoWrapper = styled.div`
  border-radius: 10px;
  background: var(--primaryBg);
  display: flex;
  z-index: 1;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
`
export const InfoContent = styled.div`
  display: flex;
  margin: auto;
  /* padding: 50px; */
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const Column1 = styled.div`
  width: 50%;
  margin-bottom: 15px;
  padding: 50px;
  @media screen and (max-width: 460px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 64px;
  font-weight: 800;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
  }
  @media screen and (max-width: 460px) {
    font-size: 48px;
    margin-top: 20px;
    text-align: center;
  }
`
export const Description = styled.p`
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 35px 0px;

  @media screen and (max-width: 815px) {
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
  }
  @media screen and (max-width: 460px) {
    display: none;
  }
`
export const Column2 = styled.div`
  width: 50%;
  padding-top: 50px;
  @media screen and (max-width: 460px) {
    padding-top: 0px;
    width: 100%;
  }
`
export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;

  /* or 178% */
  text-align: center;
  font-feature-settings: 'salt' on, 'liga' off;

  color: #352e68;
  margin-top: 40px;
  color: var(--tertiaryColor);
  @media screen and (max-width: 815px) {
    margin: 10px;
  }
  @media screen and (max-width: 460px) {
    font-size: 15px;
    margin: 0px;
  }
`
export const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
`
export const ImgWrap = styled.div`
  width: 100%;
  @media screen and (max-width: 815px) {
    float: left;
    bottom: 0;
  }
`
export const Img = styled.img`
  float: right;
  width: 480px;
  @media screen and (max-width: 815px) {
    width: 400px;
  }
`
