import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
  overflow: hidden;
  background: ${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
  height: 100px;
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 110 !important;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1350px;
`

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  width: 350px;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
    height: 60px;
    width: 250px;
  }
`
export const NavImg = styled.img`
  margin-bottom: 15px;
  margin-top: 20px;
  width: 100%;
  height: 95%;
  @media screen and (max-width: 480px) {
    margin-top: 40px;
    height: 60px;
    width: 250px;
  }
`
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 815px) {
    display: block;
    margin-top: 35px;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    transform: translate (-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 815px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLinks = styled(LinkS)`
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid var(--primaryColor);
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 815px) {
    display: none;
  }
`

export const NavBtnLink = styled.button`
  border-radius: 10px;
  background: #352e68;
  white-space: wrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    /* background: #fff; */
    /* color: #010606; */
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`
