import React, { useState } from 'react'
import Navbar from '../../Navbar'
import Sidebar from '../../Sidebar'

import { InfoContainer, InfoWrapper, SectionTitle } from './ContactUsElements'

const ContactUs = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Navbar toggle={toggle} info='Community' />
      <Sidebar isOpen={isOpen} toggle={toggle} info='Community' />
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>ContactUs</SectionTitle>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default ContactUs
