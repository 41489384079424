import React, { useEffect } from 'react'

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Heading,
  FormContainer,
  LabelName,
  LabelCountry,
  TryDiv,
  LabelEmail,
  BtnWrap,
  JoinBtn,
  Column2,
  ImgWrap,
  Img,
  LogoContainer,
  LogoWrapper,
  LogoImage,
} from './SignupElements'
// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'
const Signup = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1 data-aos='zoom-out'>
              <Heading>Sign up now to get early access!</Heading>
              <FormContainer>
                <LabelName placeholder='Your name' />
                <LabelCountry placeholder='Your country' />
                <TryDiv>
                  <LabelEmail placeholder='Your email address' />
                  <BtnWrap>
                    <JoinBtn>Join the WaitList</JoinBtn>
                  </BtnWrap>
                </TryDiv>
              </FormContainer>
            </Column1>
            <Column2>
              <ImgWrap data-aos='fade-left'>
                <Img src={require('../assets/images/signup.png')} alt='' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
        <LogoContainer>
          <LogoImage src={require('../assets/images/logo1.png')} alt='' />
          <LogoImage src={require('../assets/images/logo2.png')} alt='' />
          <LogoImage src={require('../assets/images/logo3.jpg')} alt='' />
          <LogoImage src={require('../assets/images/logo4.jpg')} alt='' />
        </LogoContainer>
      </InfoContainer>
    </>
  )
}

export default Signup
