import React, { useState, useEffect } from 'react'
import { FaCaretRight } from 'react-icons/fa'
import { FAQ1 as data1, FAQ2 as data2 } from '../assets/Datas/Datas'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  InfoRow1,
  Column1,
  Column2,
  Heading,
  Column3,
  Img,
  InfoRow2,
  Column4,
  QuestionContainer,
  QuestionItems,
  Questions,
  Question,
  QuestionDiv,
  QuestionTitle,
  ArrowHead,
  Answers,
  Answer,
  Column5,
  FaArrowDown1,
  FaArrowUp1,
  FaCaretRight1,
} from './InfoSection5Elements'
//animation kits
import Aos from 'aos'
import 'aos/dist/aos.css'
const InfoSection5 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  const [isAnswer, setIsAnswer] = useState(null)
  const toggle = (i) => {
    if (isAnswer === i) {
      return setIsAnswer(null)
    }
    setIsAnswer(i)
  }
  const [isAnswer2, setIsAnswer2] = useState(null)
  const toggle2 = (i) => {
    if (isAnswer2 === i) {
      return setIsAnswer2(null)
    }
    setIsAnswer2(i)
  }
  return (
    <>
      <InfoContainer id='id_faq'>
        <InfoWrapper data-aos='slide-left'>
          <InfoRow>
            <InfoRow1>
              <Column1>
                <Img
                  src={require('../assets/images/infosection5-1.png')}
                  alt=''
                />
              </Column1>
              <Column2>
                <Heading>Frequently Asked Questions</Heading>
              </Column2>
              <Column3>
                <Img
                  src={require('../assets/images/infosection5-2.png')}
                  alt=''
                />
              </Column3>
            </InfoRow1>
            <InfoRow2 imgStart={false}>
              <Column4>
                <QuestionContainer>
                  <QuestionItems>
                    {data1.map((item, i) => (
                      <>
                        <Questions onClick={() => toggle(i)}>
                          <Question>
                            <QuestionDiv>
                              <FaCaretRight1 />
                            </QuestionDiv>
                            <QuestionTitle>{item.questionTitle}</QuestionTitle>
                          </Question>
                          <ArrowHead>
                            {isAnswer === i ? <FaArrowUp1 /> : <FaArrowDown1 />}
                          </ArrowHead>
                        </Questions>
                        {isAnswer === i ? (
                          <Answers data-aos='fade-down'>
                            <Answer>{item.answer}</Answer>
                          </Answers>
                        ) : null}
                      </>
                    ))}
                  </QuestionItems>
                </QuestionContainer>
              </Column4>
              <Column5>
                <QuestionContainer>
                  <QuestionItems>
                    {data2.map((item, i) => (
                      <>
                        <Questions onClick={() => toggle2(i)}>
                          <Question>
                            <QuestionDiv>
                              <FaCaretRight1 />
                            </QuestionDiv>
                            <QuestionTitle>{item.questionTitle}</QuestionTitle>
                          </Question>
                          <ArrowHead>
                            {isAnswer2 === i ? (
                              <FaArrowUp1 />
                            ) : (
                              <FaArrowDown1 />
                            )}
                          </ArrowHead>
                        </Questions>
                        {isAnswer2 === i ? (
                          <Answers data-aos='fade-down'>
                            <Answer>{item.answer}</Answer>
                          </Answers>
                        ) : null}
                      </>
                    ))}
                  </QuestionItems>
                </QuestionContainer>
              </Column5>
            </InfoRow2>

            {/* <div className='a'>a</div> */}
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
  //   <FontAwesomeIcon icon="fa-solid fa-up-long" /><FontAwesomeIcon icon="fa-solid fa-down-long" /><FontAwesomeIcon icon="fa-solid fa-down-long" />
}

export default InfoSection5
