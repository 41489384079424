import React, { useEffect } from 'react'
import {
  InfoContainer,
  InfoWrapper,
  SectionHeading,
  InfoContent,
  InfoImg,
  InfoImg2,
  InfoTextWrapper,
  Description,
} from './InfoSection4Elements'

// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const InfoSection4 = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])
  return (
    <>
      <InfoContainer id='id_security'>
        <InfoWrapper>
          <SectionHeading data-aos='fade-up'>
            Keep Calm and Breathe
          </SectionHeading>
          <InfoContent>
            <InfoImg
              data-aos='fade-right'
              src={require('../../Components/assets/images/info4-1.png')}
              alt=''
            />
            <InfoTextWrapper>
              <Description>
                Security is at the forefront of everything we do on The Crypto
                Playground.
              </Description>
              <Description>
                <br />
                From the initial identification management system on the
                platform when you join, through our privacy policy and use of
                third parties, we set a benchmark with our high standards.
              </Description>
              <Description>
                <br />
                Within our simulated environment you will engage in learning
                objectives where mistakes have no repercussions before
                transitioning to real world applications where you shape your
                decisions.
              </Description>
            </InfoTextWrapper>
            <InfoImg2
              data-aos='fade-left'
              src={require('../../Components/assets/images/info4-2.png')}
              alt=''
            />
          </InfoContent>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection4
