import React, { useState, useEffect } from 'react'
import axios from 'axios'
import TestT from './TestT'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

const PreviousBtn = styled.button`
  font-size: 14px;
  padding: 5px;
`
const NextBtn = styled.button`
  font-size: 14px;
  padding: 5px;
`
const NewsApiTest = () => {
  const [coin, setCoin] = useState([])
  // const options = {
  //   method: 'GET',
  //   url: 'https://coinranking1.p.rapidapi.com/coins',
  //   params: {
  //     referenceCurrencyUuid: 'yhjMzLPhuIDl',
  //     timePeriod: '24h',
  //     'tiers[0]': '1',
  //     orderBy: 'marketCap',
  //     orderDirection: 'desc',
  //     limit: '50',
  //     offset: '0',
  //   },
  //   headers: {
  //     'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com',
  //     'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
  //   },
  // }
  // axios
  //   .request(options)
  //   .then(function (response) {
  //     console.log('response data')
  //     console.log(response.data)
  //     // setCoin(response.data)
  //     setCoin(response.data.data.coins)
  //     console.log('setCoin')
  //     console.log(coin)
  //   })
  //   .catch(function (error) {
  //     console.error(error)
  //   })
  //https://rapidapi.com/enochmwanga-21HbaFqsNfw/api/crypto-news15/
  //crypto news API documentation
  // const options = {
  //   method: 'GET',
  //   url: 'https://crypto-news15.p.rapidapi.com/news/amb',
  //   headers: {
  //     'X-RapidAPI-Host': 'crypto-news15.p.rapidapi.com',
  //     'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
  //   },
  // }

  // axios
  //   .request(options)
  //   .then(function (response) {
  //     console.log(response.data)
  //   })
  //   .catch(function (error) {
  //     console.error(error)
  //   })

  //https://rapidapi.com/atefy410-xjs2CLDENg_/api/crypto-news6/
  // const options = {
  //   method: 'GET',
  //   url: 'https://crypto-news6.p.rapidapi.com/news/yahoo',
  //   headers: {
  //     'X-RapidAPI-Host': 'crypto-news6.p.rapidapi.com',
  //     'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
  //   },
  // }

  // axios
  //   .request(options)
  //   .then(function (response) {
  //     console.log(response.data)
  //   })
  //   .catch(function (error) {
  //     console.error(error)
  //   })

  //get all cryptocurrency news
  // const options = {
  //   method: 'GET',
  //   url: 'https://crypto-news6.p.rapidapi.com/news',
  //   headers: {
  //     'X-RapidAPI-Host': 'crypto-news6.p.rapidapi.com',
  //     'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
  //   },
  // }

  // axios
  //   .request(options)
  //   .then(function (response) {
  //     console.log(response.data)
  //   })
  //   .catch(function (error) {
  //     console.error(error)
  //   })

  //get all crypto news
  //https://rapidapi.com/us.soysal/api/crytocurrency-live/
  const options = {
    method: 'GET',
    url: 'https://crytocurrency-live.p.rapidapi.com/news',
    headers: {
      'X-RapidAPI-Host': 'crytocurrency-live.p.rapidapi.com',
      'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
    },
  }

  axios
    .request(options)
    .then(function (response) {
      setCoin(response.data)
    })
    .catch(function (error) {
      console.error(error)
    })
  /////////////////////////////////////////////////////////
  // const [news, setNews] = useState(coin.slice(0, 50))
  // const [pageNumber, setPageNumber] = useState(0)
  // const newsPerPage = 2
  // const pagesVisited = pageNumber * newsPerPage
  // const pageCount = Math.ceil(coin.length / newsPerPage)
  // const displayNews = coin
  //   .slice(pagesVisited, pagesVisited + newsPerPage)
  //   .map((item) => {
  //     return (
  //       <div>
  //         <br />
  //         <p>{item.title}</p>
  //         <br />
  //       </div>
  //     )
  //   })

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected)
  // }
  ///////////////////////////////////////////

  const [news, setNews] = useState(coin.slice(0, coin.length))
  const [pageNumber, setPageNumber] = useState(0)
  const newsPerPage = 5
  const pagesVisited = pageNumber * newsPerPage
  const pageCount = Math.ceil(coin.length / newsPerPage)
  const displayNews = coin
    .slice(pagesVisited, pagesVisited + newsPerPage)
    .map((item) => {
      return (
        <div>
          <br />
          <p>{item.title}</p>
          <br />
        </div>
      )
    })
  const hanldePrevious = (pageNumber) => {
    console.log(pageNumber)
    setPageNumber((p) => {
      if (p === 0) return p
      return p - 1
    })
  }
  const handleNext = () => {
    setPageNumber((p) => {
      if (p === pageCount) return p
      return p + 1
    })
  }
  return (
    <>
      {displayNews}
      <PreviousBtn disabled={pageNumber + 1 === 1} onClick={hanldePrevious}>
        Prev
      </PreviousBtn>
      {pageNumber + 1}
      <NextBtn disabled={pageNumber === pageCount - 1} onClick={handleNext}>
        Next
      </NextBtn>
      {/* <TestT obj={coin} /> */}
      {/* {coin.map((item) => (
        <>
          <a href={item.url}>
            {item.title} */}
      {/* {item.name},{item.symbol} */}
      {/* </a>
          <p>{item.source}</p>
          <br />
        </>
      ))} */}
    </>
  )
}

export default NewsApiTest
