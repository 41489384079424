import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AiFillRightCircle } from 'react-icons/ai'

export const InfoContainer = styled.div`
  /* margin-top: 80px; */
  /* overflow: hidden; */
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  height: auto;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    padding: 100px 0;
    margin-left: 0;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  /* justify-content: center;
  align-items: center; */
`

export const SectionTitle = styled.h1`
  text-align: center;
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 32px;
  }
`
export const SectionNavbar = styled.div`
  height: 30px;
  display: flex;
  width: 100%;
  padding: 5px 0 0 0;
  margin-top: 20px;
  justify-content: space-around;
  @media screen and (max-width: 460px) {
    margin-top: 25px;
    align-items: center;
    flex-direction: column;
  }
`
export const Sections = styled(Link)`
  text-decoration: none;
  font-size: 24px;
  line-height: 16px;
  font-weight: 400;
  color: black;
  &:hover {
    color: red;
    border-bottom: 2px solid red;
  }
  @media screen and (max-width: 460px) {
    margin: 5px;
    font-size: 16px;
  }
`
export const ArticleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const VideoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  @media screen and (max-width: 460px) {
  }
`
export const Video = styled.video`
  display: block;
`
export const VideoDesc = styled.p`
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const Quote = styled.q``
export const MainContainer = styled.div`
  width: 65%;
  padding: 5px;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`
export const PrimaryArticle = styled.div`
  /* display: flex; */
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`
export const PImage = styled.img`
  width: 100%;
`
export const PImageDesc = styled.p`
  margin-top: -5px;
  padding: 5px;
  width: 100%;
  background-color: #f2f3f4;
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-align: center;
`
export const PHeading = styled.h2`
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
  line-height: 1.4em;
  color: #353638;
  margin-bottom: 10px;
  margin-top: 10px;
`
export const PDate = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: #3c404b;
  font-weight: 400;
`
export const PContent = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  color: black;
  margin: 10px 0;
  text-align: start;
`
export const LinkToArticle = styled.a`
  color: black;
  text-decoration: none;
`

export const SideContainer = styled.div`
  width: 35%;
  padding: 5px;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`
export const SideContainerTitle = styled.h1`
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  color: black;
  text-align: center;
`
export const ArticleItems = styled.div`
  margin: 10px 0;
  width: 100%;
  padding: 10px;
  border: 1px solid red;
  border-radius: 20px;
  /* color: ${({ colors }) => ('red' ? 'red' : 'black')}; */
  color: black;
`
export const STitle = styled.h2`
  text-align: center;
  font-size: 24px;
  text-decoration: none;
`
export const SDescription = styled.p`
  font-size: 16px;
`
export const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    color: red;
    text-decoration: underline;
  }
  @media screen and (max-width: 460px) {
    align-items: center;
    justify-content: center;
  }
`
export const AiFillRightCircle1 = styled(AiFillRightCircle)`
  margin-top: 2px;
`
export const VideosListContainer = styled.div`
  width: 100%;
`
export const VideosListTitle = styled.h1`
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  color: black;
  text-align: center;
  margin: 20px;
`
export const VideosListContainerTitle = styled.h1`
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  color: black;
  text-align: center;
`
export const VideosList = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 816px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const VideoListItem = styled.div`
  display: flex;
  width: 50%;
  @media screen and (max-width: 816px) {
    padding: 20px;
    width: 100%;
    margin-top: 20px;
  }
  @media screen and (max-width: 460px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const VideoImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  @media screen and (max-width: 816px) {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 460px) {
    width: 150px;
    height: 150px;
    margin: 10px;
  }
`
export const VideoContent = styled.div`
  margin-left: 5px;
`
export const VideoTitle = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
  line-height: 1.4em;
  color: #353638;
  margin-bottom: 10px;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
    color: red;
  }
  @media screen and (max-width: 816px) {
    font-size: 20px;
  }
`
export const VideoDate = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: #3c404b;
  font-weight: 400;
`
export const VideoSource = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  color: black;
  margin: 10px 0;
  text-align: start;
`
export const YoutubeVideosContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const YoutubeVideoItem = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  @media screen and (max-width: 460px) {
    width: 40%;
  }
`
export const YoutubeImage = styled.img`
  width: 100%;
  border: 1px solid black;
`
export const YoutubeTitle = styled.a`
  cursor: pointer;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4em;
  color: #353638;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
    color: red;
  }
`
export const YoutubeDate = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: #3c404b;
  font-weight: 400;
`
