import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import './test.css'

const MaintenanceContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: auto;
  margin-right: auto;
  margin-left: auto;
  /* left: 20px; */
  color: #fff;
  @media screen and (max-width: 468px) {
    padding: 100px 0;
    margin-left: 0;
  }
`
const MaintenanceWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`
const MaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const MaintenanceImg = styled.img`
  width: 50%;
`
const MaintenanceTitle = styled.h1`
  margin-top: 20px;
  font-size: 32px;
  color: black;
`
const MaintenanceDescription = styled.p``
const MaintenanceButton = styled.button`
  cursor: pointer;
  padding: 5px 10px;
  color: white;
  background: var(--primaryColor);
  border-radius: 10px;
  font-size: 16px;
`

const TestT = () => {
  let navigate = useNavigate()
  const routeChange = () => {
    let path = '/'
    navigate(path)
  }
  return (
    <>
      <div className='bg-pan-right'>
        <MaintenanceContainer>
          <MaintenanceWrapper>
            <MaintenanceContent>
              <MaintenanceImg
                src={require('../../Components/assets/images/maintenance.png')}
                alt=''
              />
              <MaintenanceTitle>We are coming soon.</MaintenanceTitle>
              <MaintenanceDescription>
                This page is down for upgrades.
              </MaintenanceDescription>
              <MaintenanceDescription>
                Please check our status page for updates.
              </MaintenanceDescription>
              <MaintenanceButton onClick={routeChange}>
                Go Home
              </MaintenanceButton>
            </MaintenanceContent>
          </MaintenanceWrapper>
        </MaintenanceContainer>
      </div>
    </>
  )
}

export default TestT
