export const youtubevideolist = [
  {
    id: '1',
    title:
      'Cyber Security in the Crypto Space with Adamnite Founder Archie Chaudhury',
    date: 'Mar 23, 2022',
    img: '1.png',
    link: 'https://www.youtube.com/watch?v=_kmAtTyz-r4',
  },
  {
    id: '2',
    title: 'Crypto Wallet Setup: Security First',
    date: 'Feb 19, 2022',
    img: '2.png',
    link: 'https://www.youtube.com/watch?v=dqWuQjBAYho',
  },
  {
    id: '3',
    title: 'Exploring Next-Gen Crypto Hardware Wallet Solution with SECUX',
    date: 'Jan 16, 2022',
    img: '3.png',
    link: 'https://www.youtube.com/watch?v=GuTgk5V3cmw',
  },
  {
    id: '4',
    title: 'Welcome to the CryptoPlaygroud',
    date: 'Dec 13, 2021',
    img: '4.png',
    link: 'https://www.youtube.com/watch?v=zPTLVCZitvI',
  },
  {
    id: '5',
    title: 'CryptoPlayground Guided Tour',
    date: 'Dec 13, 2021',
    img: '5.png',
    link: 'https://www.youtube.com/watch?v=BlePDO4ok7U',
  },
  {
    id: '6',
    title: 'Interview with Pedro: Pushing Crypto Adoption with DASH Direct',
    date: 'Aug 8, 2021',
    img: '6.png',
    link: 'https://www.youtube.com/watch?v=y60bB-Yi_BM',
  },
  {
    id: '7',
    title: 'Almost Wrecked! Exodus Wallet Scam & Crashed iPhone',
    date: 'Aug 3, 2021',
    img: '7.png',
    link: 'https://www.youtube.com/watch?v=SbL3IuSojDE',
  },
  {
    id: '8',
    title:
      'The Cryptoplayground Reddit Review: Scams and the Future of Crypto ',
    date: 'Jul 30, 2021',
    img: '8.png',
    link: 'https://www.youtube.com/watch?v=qyaaRetkqeE',
  },
  {
    id: '9',
    title: 'Thorchain Exploit & Understanding Tokens v Coins',
    date: 'Jul 26, 2021',
    img: '9.png',
    link: 'https://www.youtube.com/watch?v=yl2gpZ0Jsck',
  },
  {
    id: '10',
    title: 'Every Crypto Adopter Should Start Here: Bitcoin White Paper',
    date: 'Jul 6, 2021',
    img: '10.png',
    link: 'https://www.youtube.com/watch?v=_o6hoMlwFcU',
  },
  {
    id: '11',
    title: 'The CrytpoPlayground Intro: El Salvador Makes Bitcoin Legal Tender',
    date: 'Jun 20, 2021',
    img: '11.png',
    link: 'https://www.youtube.com/watch?v=5bB5ENGguiY',
  },
]
