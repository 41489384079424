import React, { useState } from 'react'
import Navbar from '../../Navbar'
import Sidebar from '../../Sidebar'
import { InfoContainer, InfoWrapper, SectionTitle } from '../Team/TeamElements'

const Team = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Team</SectionTitle>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Team
