import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const FooterContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  background-color: #b3dbdb;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const FooterWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 815px) {
    justify-content: space-around;
  }
  @media screen and (max-width: 460px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const FooterLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 100px;
  @media screen and (max-width: 1000px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 460px) {
    width: 300px;
    margin-left: 0;
    justify-content: center;
    align-items: center;
    /* align-items: center; */
    /* margin-left: 90px; */
  }
`
export const TnP = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`
export const FooterImg = styled.img`
  cursor: pointer;
  width: 350px;
  height: 90px;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
  @media screen and (max-width: 460px) {
    width: 200px;
    height: 75px;
  }
`
export const FooterCopyright = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 32px;
  color: #7879f1;
  margin-bottom: 30px;
`
export const FooterText = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #7879f1;

  &:hover {
    color: var(--primaryColor);
    transition: 0.3 ease-out;
  }
`
export const FooterLinksContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  /* margin-left: 50px; */
  @media screen and (max-width: 815px) {
    flex-wrap: wrap;
  }
`

export const FooterLinksWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */

  @media screen and (max-width: 815px) {
    flex-direction: column;
  }
`

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 150px;
  box-sizing: border-box;
  color: #fff;
`

export const FooterLinkTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 20px;

  /* identical to box height */
  letter-spacing: 0.1em;

  color: #352e68;
`

export const FooterLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #7879f1;

  &:hover {
    color: var(--primaryColor);
    transition: 0.3 ease-out;
  }
`
export const FooterLink2 = styled(LinkS)`
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #7879f1;

  &:hover {
    color: var(--primaryColor);
    transition: 0.3 ease-out;
  }
`
