import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NewsData2 } from '../../assets/Datas/TokenomicsData/NewsData2'
import { cryptonews_api } from '../../assets/Datas/NewsApi2'
import { animateScroll as scroll } from 'react-scroll'

import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
  CoinsSection,
  CoinSearchBar,
  SearchTitle,
  CoinForm,
  CoinInput,
  CoinContainer,
  CoinRow,
  Coins,
  CoinImage,
  CoinName,
  CoinSymbol,
  CoinsData,
  CoinPrice,
  CoinPriceChange,
  CoinVolume,
  CoinPercentGreen,
  CoinPercentRed,
  CoinMarketCap,
  NewsSection,
  NewsWrapper,
  NewsSideBar,
  NewsSideBarContainer,
  NewSidebarHeading,
  SideNewsContainer,
  SideNewsLink,
  SideNewsSource,
  SidebarNewsPagination,
  PreviousBtn,
  PageNumberDisplay,
  NextBtn,
  NewsSidebarContainer2,
  ViewMoreNewsBtn,
  NewsContent,
  AllNews,
  SidebarAllNews,
  SidebarAllNewsWrapper,
  NewsLink,
  SidebarAllNewsCard,
  SidebarAllNewsImg,
  SidebarAllNewsCardContent,
  SidebarAllNewsCardSource,
  SidebarAllNewsCardDate,
  SidebarAllNewsCardTitle,
  AllMainNews,
} from './NewsElements'
import Card from './Card'
import MainCard from './MainCard'

const News = () => {
  //scroll
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])
  //API call for coins
  const [coins, setCoins] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    axios
      .get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=5&page=1&sparkline=false'
      )
      .then((res) => {
        setCoins(res.data)
        // console.log(res.data)
      })
      .catch((error) => console.log(error))
  }, [])

  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  const filteredCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLowerCase())
  )

  //API calls for News
  const [news, setNews] = useState([])
  const options1 = {
    method: 'GET',
    url: 'https://crytocurrency-live.p.rapidapi.com/news',
    headers: {
      'X-RapidAPI-Host': 'crytocurrency-live.p.rapidapi.com',
      'X-RapidAPI-Key': '3728e58452msh116974bb24ae65ap1e81e5jsn27c7f01bf48b',
    },
  }
  useEffect(() => {
    axios
      .request(options1)
      .then(function (response) {
        setNews(response.data)
        console.log(response.data)
      })
      .catch(function (error) {
        console.error(error)
      })
  }, [])
  const [pageNumber, setPageNumber] = useState(0)
  const newsPerPage = 5
  const pagesVisited = pageNumber * newsPerPage
  const pageCount = Math.ceil(news.length / newsPerPage)

  const hanldePrevious = (pageNumber) => {
    console.log(pageNumber)
    setPageNumber((p) => {
      if (p === 0) return p
      return p - 1
    })
  }
  const handleNext = () => {
    setPageNumber((p) => {
      if (p === pageCount) return p
      return p + 1
    })
  }
  const num = 2
  return (
    <>
      <InfoContainer scrollNav={scrollNav}>
        <InfoWrapper>
          <SectionTitle>Crypto Coins</SectionTitle>
          <CoinsSection>
            <CoinSearchBar>
              <SearchTitle>Search a Coin</SearchTitle>
              <CoinForm>
                <CoinInput onChange={handleChange} placeholder='Search' />
              </CoinForm>
            </CoinSearchBar>
            <CoinRow>
              <Coins>
                <CoinName>Coin Name</CoinName>
                <CoinSymbol>Symbol</CoinSymbol>
              </Coins>
              <CoinsData>
                <CoinPrice>Price</CoinPrice>
                <CoinVolume>Volume</CoinVolume>
                <CoinPriceChange>Change($)</CoinPriceChange>
                <CoinMarketCap>Market Cap</CoinMarketCap>
              </CoinsData>
            </CoinRow>
            {filteredCoins.map((item) => {
              return (
                <>
                  <CoinContainer>
                    {/* {coins.map((item) => ( */}
                    <>
                      <CoinRow>
                        <Coins>
                          <CoinImage src={item.image} alt='' />
                          <CoinName>{item.name}</CoinName>
                          <CoinSymbol>{item.symbol}</CoinSymbol>
                        </Coins>
                        <CoinsData>
                          <CoinPrice>{item.current_price}</CoinPrice>
                          <CoinVolume>{item.total_volume}</CoinVolume>
                          {item.price_change_percentage_24h < 0 ? (
                            <CoinPercentRed>
                              {item.price_change_percentage_24h}
                            </CoinPercentRed>
                          ) : (
                            <CoinPercentGreen>
                              {item.price_change_percentage_24h}
                            </CoinPercentGreen>
                          )}
                          <CoinMarketCap>
                            Mkt Cap:${item.market_cap}
                          </CoinMarketCap>
                        </CoinsData>
                      </CoinRow>
                    </>
                    {/* ))} */}
                  </CoinContainer>
                </>
              )
            })}
          </CoinsSection>
          <NewsSection>
            <SectionTitle>News Section</SectionTitle>
            <NewsWrapper>
              <NewsSideBar>
                <NewsSideBarContainer>
                  <NewSidebarHeading>All Crypto News :</NewSidebarHeading>
                  {/* {displayNews} */}
                  {news
                    .slice(pagesVisited, pagesVisited + newsPerPage)
                    .map((item) => {
                      return (
                        <SideNewsContainer>
                          <SideNewsLink href={item.url}>
                            {item.title}
                          </SideNewsLink>
                          <SideNewsSource>Source: {item.source}</SideNewsSource>
                        </SideNewsContainer>
                      )
                    })}
                  <SidebarNewsPagination>
                    <PreviousBtn
                      disabled={pageNumber + 1 === 1}
                      onClick={hanldePrevious}
                    >
                      Prev
                    </PreviousBtn>
                    <PageNumberDisplay>{pageNumber + 1}</PageNumberDisplay>

                    <NextBtn
                      disabled={pageNumber === pageCount - 1}
                      onClick={handleNext}
                    >
                      Next
                    </NextBtn>
                  </SidebarNewsPagination>
                </NewsSideBarContainer>
                <NewsSidebarContainer2>
                  <NewSidebarHeading>Recommended</NewSidebarHeading>
                  <Card num={5} />

                  <ViewMoreNewsBtn
                    to='id_all_sidenews'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                  >
                    View More News{' '}
                  </ViewMoreNewsBtn>
                </NewsSidebarContainer2>
              </NewsSideBar>
              <NewsContent>
                <MainCard />
                <ViewMoreNewsBtn
                  to='id_all_main_news'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  View More News{' '}
                </ViewMoreNewsBtn>
              </NewsContent>
            </NewsWrapper>
          </NewsSection>
          <AllNews>
            <SidebarAllNews id='id_all_sidenews'>
              <NewSidebarHeading>All recommended news</NewSidebarHeading>
              <SidebarAllNewsWrapper>
                {NewsData2.map((item) => {
                  return (
                    <>
                      <NewsLink href={item.url}>
                        <SidebarAllNewsCard>
                          <SidebarAllNewsImg
                            src={require('../../../Components/assets/images/news.jpg')}
                            alt='img'
                          />
                          <SidebarAllNewsCardContent>
                            <SidebarAllNewsCardSource>
                              {item.source.title}
                            </SidebarAllNewsCardSource>
                            <SidebarAllNewsCardDate>
                              {item.published_at}
                            </SidebarAllNewsCardDate>
                            <SidebarAllNewsCardTitle>
                              {item.title}
                            </SidebarAllNewsCardTitle>
                          </SidebarAllNewsCardContent>
                        </SidebarAllNewsCard>
                      </NewsLink>
                    </>
                  )
                })}
              </SidebarAllNewsWrapper>
            </SidebarAllNews>
          </AllNews>
          <AllNews>
            <AllMainNews id='id_all_main_news'>
              <NewSidebarHeading>Crypto News</NewSidebarHeading>
              <SidebarAllNewsWrapper>
                {cryptonews_api.map((item) => {
                  return (
                    <>
                      <NewsLink href={item.url}>
                        <SidebarAllNewsCard>
                          <SidebarAllNewsImg src={item.image_url} alt='img' />
                          <SidebarAllNewsCardContent>
                            <SidebarAllNewsCardSource>
                              {item.source_name}
                            </SidebarAllNewsCardSource>
                            <SidebarAllNewsCardDate>
                              {item.date}
                            </SidebarAllNewsCardDate>
                            <SidebarAllNewsCardTitle>
                              {item.title}
                            </SidebarAllNewsCardTitle>
                          </SidebarAllNewsCardContent>
                        </SidebarAllNewsCard>
                      </NewsLink>
                    </>
                  )
                })}
              </SidebarAllNewsWrapper>
            </AllMainNews>
          </AllNews>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default News
