import styled from 'styled-components'

export const InfoContainer = styled.div`
  margin-top: 80px;
  overflow: hidden;
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    padding: 100px 0;
    margin-left: 0;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  justify-content: center;
  align-items: center;
`

export const SectionTitle = styled.h1`
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 32px;
  }
`
export const InfoRow1 = styled.div`
  /* border: 1px solid red; */
  padding-top: 50px;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: 'coll1 coll2';
  @media screen and (max-width: 480px) {
    grid-template-areas: 'coll1' 'coll2';
    /* padding: 50px; */
  }
`
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: coll1;
`
export const SmartDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: coll2;
`
export const HeadingOne = styled.h1`
  color: var(--primaryColor);
  font-size: 36px;
  line-height: 3em;
  @media screen and (max-width: 460px) {
    font-size: 24px;
    line-height: 2em;
  }
`
export const SubHeadingOne = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--primaryColor);
`

export const TableName = styled.div`
  border: 1px solid black;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`

export const TableRowHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 50%;

  background-image: linear-gradient(
    to right,
    rgb(251, 55, 162),
    rgb(188, 4, 105)
  );
`
export const TableHeading = styled.h1`
  padding: 20px;
  justify-content: center;
  /* border-radius: 20px; */
  border: 1px solid black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  /* padding: 20px 30px; */
  color: #fff;
  width: 50%;
  @media screen and (max-width: 460px) {
    font-size: 18px;
    line-height: 2em;
  }
`
export const DataCenter = styled.div`
  /* border: 1px solid yellow; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TableRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
export const TableData = styled.p`
  border: 1px solid black;
  /* padding-left: 100px; */
  /* border: 1px solid blue; */
  /* align-self: center; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flexbox;
  @media screen and (max-width: 460px) {
    font-size: 14px;
  }
`
export const InfoRow2 = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: 1350px;
  margin: auto;
  margin-top: 50px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
`
export const InfoRow2Wrapper = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-columns: minmax(1fr);
  grid-template-areas: 'col1 col2 col3 col4';

  @media screen and (max-width: 460px) {
    grid-template-areas: 'col1' 'col2' 'col3' 'col4';
  }
`
export const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 460px) {
    margin-left: 30px;
    margin-top: 20px;
  }
`
export const RoadMapImg = styled.img`
  width: 150px;
  height: 150px;
`
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const DetailTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
`
export const DetailsP = styled.p``
export const Col1 = styled.div`
  width: 300px;
  /* width: 100%; */
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`
export const Col2 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`
export const Col3 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col3;
`
export const Col4 = styled.div`
  width: 300px;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col4;
`
export const InfoRow3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const SupportImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const Img1 = styled.img`
  margin: 30px;
  border: 1px solid black;
  width: 250px;
  height: 80px;
`
