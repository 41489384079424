import React, { useEffect } from 'react'
import { ButtonType2 } from '../assets/buttons/Buttons'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  Heading,
  Description,
  ImgScreen,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
} from './InfoSection3Elements'
import Aos from 'aos'
import 'aos/dist/aos.css'

const InfoSection3 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <InfoContainer id='id_marketplace' data-aos='zoom-in-up'>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <Heading>The Big Marketplace</Heading>
                <Description>
                  Since the early days of the internet, accessibiltiy has been
                  the driving force to connect users to marketplaces.
                </Description>
                <ImgScreen>
                  <ImgWrap>
                    <Img src={require('../assets/images/info3.png')} alt='' />
                  </ImgWrap>
                </ImgScreen>
                <Description>
                  <br />
                  The CryptoPlayground marketplace offers you the opportunity to
                  learn and gain access to a range of blockchain projects as
                  well as our emporium of games, books, merchandise and events.
                </Description>{' '}
                <BtnWrap>
                  <a href='https://thecryptoplayground.com/'>
                    <ButtonType2>Find Out More</ButtonType2>
                  </a>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={require('../assets/images/info3.png')} alt='' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection3
