import React from 'react'
import { NavbarData } from '../assets/Datas/NavbarDatas'
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarBtnWrap,
  SidebarRoute,
} from './SidebarElements'

const Sidebar = (props) => {
  return (
    <SidebarContainer isOpen={props.isOpen} onClick={props.toggle}>
      <Icon onClick={props.toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {NavbarData.map((item) => (
            <>
              {item.id === `${props.info}` ? (
                <>
                  {item.navbarMenu.map((item2) => (
                    <>
                      <SidebarLink
                        to={item2.navLink}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-90}
                        onClick={props.toggle}
                      >
                        {item2.navName}
                      </SidebarLink>
                    </>
                  ))}
                </>
              ) : (
                ''
              )}
            </>
          ))}
        </SidebarMenu>
        {`${props.info}` === 'Home' ? (
          <>
            <SidebarBtnWrap>
              <SidebarRoute to='/'>Launch App</SidebarRoute>
            </SidebarBtnWrap>
          </>
        ) : (
          ''
        )}
        {/* <SidebarBtnWrap>
          <SidebarRoute>Launch App</SidebarRoute>
        </SidebarBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
