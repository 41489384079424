import styled from 'styled-components'

export const InfoContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  /* margin-top: 20px; */
  overflow: hidden;
`

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 600px;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 780px) {
    height: 1030px;
  }
`

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  justify-content: center;
  align-items: center;
  grid-template-areas: 'col2 col1';
  @media screen and (max-width: 786px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
  }
`

export const Column1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: col1;
  /* @media screen and (max-width: 460px) {
    width: 300px;
  } */
`

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  @media screen and (max-width: 780px) {
    justify-content: center;
  }
`
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    font-size: 32px;
    line-height: 1;
    font-weight: 600;
  }
  @media screen and (max-width: 460px) {
    font-size: 28px;
  }
`
export const Description = styled.p`
  align-self: center;
  color: var(--primaryColor);
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;

  /* or 67% */
  text-align: center;
  /* @media screen and (max-width: 760px) {
    margin-left: 40px;
  }*/
  @media screen and (max-width: 815px) {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
  }
  @media screen and (max-width: 460px) {
    font-size: 22px;
    line-height: 32px;
  }
`
export const DescriptionHead = styled.h3`
  margin-top: 50px;
  color: black;
  font-size: 18px;
  font-weight: 500;
`
export const DescriptionImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 815px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const ImageContent = styled.img`
  height: 100%;
  width: 250px;
  padding: 20px;
  @media screen and (max-width: 815px) {
    width: 150px;
  }
  @media screen and (max-width: 460px) {
    /* width: 200px; */
    margin-top: 20px;
  }
`

export const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
`

export const ImgWrap = styled.div`
  width: 100%;
  justify-content: center;
`

export const Img = styled.img`
  width: 550px;
  @media screen and (max-width: 815px) {
    width: 370px;
  }
`
