import styled from 'styled-components'

export const InfoContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 460px) {
    padding: 0;
  }
`

export const InfoWrapper = styled.div`
  background: var(--secondaryBg);
  border-radius: 10px;
  display: grid;
  z-index: 1;
  /* height: 500px; */
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
`

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  justify-content: center;
  align-items: center;
  grid-template-areas: 'col1 col2';
`

export const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  @media screen and (max-width: 815px) {
    padding: 50px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 540px;
  padding-top: 0;
  @media screen and (max-width: 815px) {
    max-width: 500px;
    justify-content: center;
  }
`
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 58px;
  line-height: 48px;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 815px) {
    font-size: 40px;
  }
  @media screen and (max-width: 460px) {
    text-align: center;
  }
`
export const Description = styled.p`
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 400;
  @media screen and (max-width: 460px) {
    padding: 20px;
    text-align: center;
  }
`
export const ImgScreen = styled.div`
  display: none;
  @media screen and (max-width: 460px) {
    display: flex;
  }
`

export const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
`
export const Column2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
  @media screen and (max-width: 460px) {
    display: none;
  }
`

export const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
`

export const Img = styled.img`
  width: 100%;
  @media screen and (max-width: 815px) {
    width: 300px;
  }
`
