import React, { useState, useEffect } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Hero from '../Components/HeroSection'
import Signup from '../Components/Signup'
import InfoSection2 from '../Components/InfoSection/InfoSection2'
import InfoSection3 from '../Components/InfoSection/InfoSection3'
import InfoSection4 from '../Components/InfoSection/InfoSection4'
import InfoSection5 from '../Components/InfoSection/InfoSection5'
import Social from '../Components/SocialSection'
import ServiceSection from '../Components/ServicesSection'
import Contacts from '../Components/Contacts'
import Footer from '../Components/Footer'
import Roadmap from '../Components/Roadmap'
import InfoSection1 from '../Components/InfoSection/InfoSection1'
import HeroSection2 from '../Components/InfoSection'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Navbar toggle={toggle} info='Home' />
      <Sidebar isOpen={isOpen} toggle={toggle} info='Home' />
      <Hero />
      <HeroSection2 />
      <ServiceSection />
      <Social />
      <Signup />
      <InfoSection1 />
      <InfoSection2 />
      <InfoSection3 />
      <InfoSection4 />
      <Roadmap />
      <InfoSection5 />
      <Contacts />
      <Footer />
    </>
  )
}

export default Home
