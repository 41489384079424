import React, { useState } from 'react'
import Navbar from '../../Navbar'
import Sidebar from '../../Sidebar'
import { Worker } from '@react-pdf-viewer/core'
import whitepaper from '../../../Components/assets/PdfFiles/whitepaper.pdf'
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'
//import of default layout plugin
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
  PdfViewer,
} from './WhitepaperElements'
const Whitepaper = () => {
  //creating instance for default layout of pdf viewer
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Whitepaper</SectionTitle>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
            {/* viewing components */}
            <PdfViewer>
              <Viewer
                plugins={[defaultLayoutPluginInstance]}
                fileUrl={whitepaper}
              />
            </PdfViewer>
          </Worker>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Whitepaper
