import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import Journeys from '../Components/Playground/Journeys'
import Sidebar from '../Components/Sidebar'

const Playground = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Journeys />
    </>
  )
}

export default Playground
