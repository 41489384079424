import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import Sidebar from '../Components/Sidebar'
import FAQ from '../Components/Discover/FAQ'

const Discover = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      {/* <Navbar toggle={toggle} info='Discover' />
      <Sidebar isOpen={isOpen} toggle={toggle} info='Discover' />
       */}
      <FAQ />
    </>
  )
}

export default Discover
