import React, { useState } from 'react'
import Governance from '../Components/Community/Governance'
import Navbar from '../Components/Navbar'
import Sidebar from '../Components/Sidebar'

const Community = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Governance />
    </>
  )
}

export default Community
