import React, { useState } from 'react'
import video1 from '../../assets/videos/video1.mp4'
import { youtubevideolist } from '../../assets/Datas/videolist'

import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
  SectionNavbar,
  Sections,
  ArticleContainer,
  VideoContainer,
  Video,
  VideoDesc,
  Quote,
  MainContainer,
  PrimaryArticle,
  PImage,
  PImageDesc,
  PHeading,
  PDate,
  PContent,
  LinkToArticle,
  SideContainer,
  SideContainerTitle,
  ArticleItems,
  STitle,
  SDescription,
  LinkDiv,
  AiFillRightCircle1,
  VideosListContainer,
  VideosListContainerTitle,
  VideosListTitle,
  VideosList,
  VideoListItem,
  VideoImage,
  VideoContent,
  VideoTitle,
  VideoDate,
  VideoSource,
  YoutubeVideosContainer,
  YoutubeVideoItem,
  YoutubeImage,
  YoutubeTitle,
  YoutubeDate,
} from './PressElements'

const Press = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Press</SectionTitle>
          <SectionNavbar>
            <Sections to=''>Crypto News</Sections>
            <Sections to=''>Community Stories</Sections>
            <Sections to=''>Upcoming Events</Sections>
          </SectionNavbar>

          <ArticleContainer>
            <MainContainer>
              <VideoContainer>
                <video width='100%' controls>
                  <source src={video1} />
                </video>
                <VideoDesc>
                  <Quote>
                    The CryptoPlayground is a new education platform, custom
                    designed to teach users about the crypto ecosystem.
                  </Quote>{' '}
                  - Susan Toby
                </VideoDesc>
              </VideoContainer>

              <PrimaryArticle>
                <PImage
                  src={require('../../assets/images/press_img.png')}
                  alt=''
                />
                <PImageDesc>
                  START in Melbourne, SCALE to Southeast Asia program
                  participants at the Goods Shed North in Docklands
                </PImageDesc>
                <PHeading>
                  Supporting startups to start, grow and go global
                </PHeading>
                <PDate>March 2022</PDate>
                <PContent>
                  We’ve helped founders of new Melbourne startups forge
                  connections with Singapore, Indonesia, and Vietnam through our
                  START in Melbourne, SCALE to Southeast Asia program.
                </PContent>
                <PContent>
                  The pilot program brought together early-stage founders with
                  experts from the startup, government and university sectors
                  for 30 workshops, mentoring and networking sessions over five
                  days at local innovation hubs, with virtual connections into
                  Southeast Asia.
                </PContent>
                <PContent>
                  With Southeast Asia on track to be the fourth largest economy
                  in the world by 2030, engagement with the region is important
                  to Melbourne’s recovery, and to boosting our reputation as an
                  innovation city.
                  <LinkDiv>
                    <LinkToArticle href='https://magazine.melbourne.vic.gov.au/supporting-startups-to-start-grow-and-go-global-from-melbourne/'>
                      Read More
                    </LinkToArticle>
                    <AiFillRightCircle1 />
                  </LinkDiv>
                </PContent>
              </PrimaryArticle>
            </MainContainer>
            <SideContainer>
              <SideContainerTitle>Related Articles</SideContainerTitle>
              <ArticleItems>
                <STitle>Tile one</STitle>
                <SDescription>
                  With Southeast Asia on track to be the fourth largest economy
                  in the world by 2030, engagement with the region is important
                  to Melbourne’s recovery, and to boosting our reputation as an
                  innovation city.
                </SDescription>
                <LinkDiv>
                  <LinkToArticle href=''>Find out more </LinkToArticle>
                  <AiFillRightCircle1 />
                </LinkDiv>
              </ArticleItems>
              <ArticleItems colors='black'>
                <STitle>Tile two</STitle>
                <SDescription>
                  With Southeast Asia on track to be the fourth largest economy
                  in the world by 2030, engagement with the region is important
                  to Melbourne’s recovery, and to boosting our reputation as an
                  innovation city.
                </SDescription>
                <LinkDiv>
                  <LinkToArticle href=''>Find out more </LinkToArticle>
                  <AiFillRightCircle1 />
                </LinkDiv>
              </ArticleItems>
              <ArticleItems>
                <STitle>Tile three</STitle>
                <SDescription>
                  With Southeast Asia on track to be the fourth largest economy
                  in the world by 2030, engagement with the region is important
                  to Melbourne’s recovery, and to boosting our reputation as an
                  innovation city.
                </SDescription>
                <LinkDiv>
                  <LinkToArticle href=''>Find out more </LinkToArticle>
                  <AiFillRightCircle1 />
                </LinkDiv>
              </ArticleItems>
            </SideContainer>
          </ArticleContainer>
          <VideosListContainer>
            {/* <VideosListContainerTitle>Related Videos</VideosListContainerTitle> */}
            <VideosListTitle>Podcast Videos</VideosListTitle>
            <VideosList>
              <VideoListItem>
                <VideoImage
                  src={require('../../assets/images/videoimages/interview.png')}
                  alt=''
                />
                <VideoContent>
                  <VideoTitle href='https://www.youtube.com/watch?v=RQO9je3NZkY'>
                    Episode 201 - Interview with Mike Philbert - CEO of The
                    Crypto Playground
                  </VideoTitle>
                  <VideoDate>Jul 11, 2021</VideoDate>
                  <VideoSource>
                    An interview of Mike Philbert, the CEO of the Crypto
                    Playground.
                  </VideoSource>
                </VideoContent>
              </VideoListItem>
              <VideoListItem>
                <VideoImage
                  src={require('../../assets/images/videoimages/communication.png')}
                  alt=''
                />
                <VideoContent>
                  <VideoTitle href='https://drive.google.com/file/d/1t1CQEC9S8CqrecE5hWL6v7VEcNqFdyey/view'>
                    Communication is Key - Susan Toby
                  </VideoTitle>
                  <VideoDate>Jul 11, 2021</VideoDate>
                  <VideoSource>
                    Podcast given by Deepak Daniel and Susan Toby, Co-Founder,
                    English Startup Academy and Innovations
                  </VideoSource>
                </VideoContent>
              </VideoListItem>
            </VideosList>
            <VideosListTitle>YouTube Videos</VideosListTitle>
            <YoutubeVideosContainer>
              {youtubevideolist.map((item) => {
                return (
                  <>
                    <YoutubeVideoItem>
                      <YoutubeImage
                        src={require(`../../assets/images/videoimages/${item.img}`)}
                        alt=''
                      />
                      <YoutubeTitle href={item.link}>{item.title}</YoutubeTitle>
                      <YoutubeDate>{item.date}</YoutubeDate>
                    </YoutubeVideoItem>
                  </>
                )
              })}
            </YoutubeVideosContainer>
          </VideosListContainer>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Press
