import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Playground from './Pages/Playground'
import Community from './Pages/Community'
import Discover from './Pages/Discover'
import GlobalStyle from './globalStyles'
import Registration from './Pages/Registration'
import Tokenomics from './Pages/Tokenomics'
import Relationship from './Components/Discover/Relationship'
import Team from './Components/Discover/Team'
import Whitepaper from './Components/Discover/Whitepaper'
import FAQ from './Components/Discover/FAQ'
import Governance from './Components/Community/Governance'
import ContactUs from './Components/Community/ContactUs'
import Social from './Components/Community/Social'
import News from './Components/Community/News'
import Press from './Components/Community/Press'
import Journeys from './Components/Playground/Journeys'
import Marketplace from './Components/Playground/Marketplace'
import Security from './Components/Playground/Security'
import TermConditions from './Components/TermsCondition'
import PrivacyPolicy from './Components/Privacy'
import TestT from './Components/APITester/TestT'

ReactDOM.render(
  <Router>
    <GlobalStyle />
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/playground' element={<Playground />} />
      <Route path='/community' element={<Community />} />
      <Route path='/discover' element={<Discover />} />
      <Route path='/registration' element={<Registration />} />
      <Route path='/discover/tokenomics' element={<Tokenomics />} />
      <Route path='/discover/relationship' element={<Relationship />} />
      <Route path='/discover/team' element={<Team />} />
      <Route path='/discover/whitepaper' element={<Whitepaper />} />
      <Route path='/discover/faq' element={<FAQ />} />
      <Route path='/community/governance' element={<Governance />} />
      <Route path='/community/contactus' element={<ContactUs />} />
      <Route path='/community/social' element={<Social />} />
      <Route path='/community/news' element={<News />} />
      <Route path='/community/press' element={<Press />} />
      <Route path='/playground/journeys' element={<Journeys />} />
      <Route path='/playground/marketplace' element={<Marketplace />} />
      <Route path='/playground/security' element={<Security />} />
      <Route path='/termscondition' element={<TermConditions />} />
      <Route path='/privacy' element={<PrivacyPolicy />} />
      <Route path='/maintenance' element={<TestT />} />
    </Routes>
  </Router>,
  document.getElementById('root')
)
