export const FAQ1 = [
  {
    id: 1,
    questionTitle: 'What do you stand to gain? ',
    answer:
      'The ability to confidently play, make mistakes and learn in safety within our cryptocurrency education platform will help you transition to everyday usage.',
  },
  {
    id: 2,
    questionTitle:
      'What is the difference between this crypto education and others online? ',
    answer:
      'We are the world’s first secure, simulated, crypto education platform where all information is located in one place. Within The Crypto Playground you can learn to invest as well as play with non- fungible tokens, discover how to create a wallet, stake, learn about the different currencies and exchanges and more.',
  },
  {
    id: 3,
    questionTitle: 'What is a simulated crypto platform? ',
    answer:
      'This is created when a computer simulates in 3 dimensions a real world as well as in imaginary world environment and a person can interact with it. The experience is as close to ‘real life’ as possible. however, the additional benefit is that it allows learners to ‘reset’ the scenario and try alternative strategies and approaches safely.',
  },
]
export const FAQ2 = [
  {
    id: 1,
    questionTitle: 'How secure is this platform? ',
    answer:
      'One of the co-founders is a US military veteran and cybersecurity expert so all personal information on the platform is secure. The learning environment is safe for you to experience this complex ecosystem.',
  },
  {
    id: 2,
    questionTitle:
      'What repercussions are there if I make mistakes on this platform? ',
    answer:
      'There are no repercussions as we learn by making mistakes and building our skills. You can confidently go over and over your learning journeys and be safe in the knowledge that there are no detrimental effects within this platform.',
  },
  {
    id: 3,
    questionTitle: 'How do I transition to real world usage?  ',
    answer:
      'When ready you can set up your real wallet, use a blockchain/crypto debit card and more outside of the learning environment. You can practice doing these things within the platform safely and build your confidence however once you leave the platform, you take personal responsibility for your choices.',
  },
]
