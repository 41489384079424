import styled from 'styled-components'

export const InfoContainer = styled.div`
  margin-top: 80px;
  overflow: hidden;
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    padding: 100px 0;
    margin-left: 0;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  justify-content: center;
  align-items: center;
`

export const SectionTitle = styled.h1`
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 32px;
  }
`
export const PdfViewer = styled.div`
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  height: 800px;
  @media screen and (max-width: 460px) {
    margin-top: 20px;
  }
`
