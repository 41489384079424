import React, { useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'

import {
  InfoContainer,
  InfoWrapper,
  SectionTitle,
} from './RegistrationElements'

const Registration = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Navbar toggle={toggle} info='Home' />
      <Sidebar isOpen={isOpen} toggle={toggle} info='Home' />
      <InfoContainer>
        <InfoWrapper>
          <SectionTitle>Registration</SectionTitle>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Registration
