import React, { useState, useEffect } from 'react'
import { NewsData } from '../../assets/Datas/NewsData'
import { NewsData2 } from '../../assets/Datas/TokenomicsData/NewsData2'
import axios from 'axios'
import styled from 'styled-components'
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
`
const NewsCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`
const NewsImg = styled.img`
  height: 100px;
  padding: 10px;
`
const NewsLink = styled.a`
  display: flex;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
`
const CardTitle = styled.h1`
  font-size: 32px;
`
const CardDesc = styled.p`
  color: black;
`
const CardUrl = styled.p`
  color: black;
`
const CardDate = styled.p`
  color: black;
`
const ReadMoreBtn = styled.div`
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  :hover {
    color: blue;
  }
`

const Card = ({ num }) => {
  ////////////////////////////////////////////
  //crypto news 16
  //// herokuapp:https://cors-anywhere.herokuapp.com/

  // const [mainnews, setMainNews] = useState([])
  // useEffect(() => {
  //   axios
  //     .get(
  //       'https://cors-anywhere.herokuapp.com/https://newsdata.io/api/1/news?apikey=pub_7235212b4c66770d9a2207ae08200a126692&q=cryptocurrency&category=business',
  //       {
  //         headers: {
  //           'Access-Control-Allow-Origin': '*',
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setMainNews(res.data.results)
  //       console.log(res.data.results)
  //     })
  //     .catch((error) => alert('Error'))
  // }, [])

  return (
    <>
      <CardContainer>
        {NewsData2.slice(0, num).map((item) => {
          return (
            <>
              <NewsCard>
                <NewsImg
                  src={require('../../../Components/assets/images/news.jpg')}
                  alt=''
                />
                <NewsLink href={item.url}>{item.title}</NewsLink>
                <p>{item.source.title}</p>
                <p>{item.published_at}</p>
              </NewsCard>
            </>
          )
        })}
      </CardContainer>
    </>
  )
}

export default Card
