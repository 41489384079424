import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
*, ::before, ::after{
    box-sizing: border-box;
}

:root{
    --primaryColor: #352E68;
    --secondaryColor: #fff;
    --tertiaryColor: #020842;
    --primaryBg: #c8c8ed;
    --secondaryBg: #B3DBDB;
}
body{
    margin:0;
    padding:0 ;
    font-family:'Museo' ;
    /* overflow-x:hidden ; */
    /* over-flow scroll hidden */
}
h1, h2{
    color: var(---primaryColor) ;
}
h1{
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 72px ;
    font-weight: 800 ;
}
h2{
    font-size: 48px ;
    font-weight: 800 ;
}
h3{
    font-weight: 800;
    font-size: 48px;
}
p{
    font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 32px;

/* or 178% */
font-feature-settings: 'salt' on, 'liga' off;

color: #352E68;
}
/* @media screen and (max-width:768px){
    h1{
    font-size: 32px;
    }
} */
`
export default GlobalStyle
