import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'

export const InfoContainer = styled.div`
  overflow-x: scroll;
  margin-top: 80px;
  overflow: hidden;
  /* display: block; */
  max-width: 1350px;
  /* margin-top: 90px; */
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 468px) {
    /* padding: 100px 0; */
    margin-left: 0;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  /* overflow: hidden; */
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  width: 100%;
  max-width: 1350px;
  /* padding: 0 36px; */
  justify-content: center;
  align-items: center;
`

export const SectionTitle = styled.h1`
  color: var(--primaryColor);
  @media screen and (max-width: 460px) {
    font-size: 32px;
  }
`
export const CoinsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-top: 50px;
`
export const CoinSearchBar = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const SearchTitle = styled.h1`
  margin-left: 20px;
  font-size: 24px;
  text-align: center;
`
export const CoinForm = styled.form``
export const CoinInput = styled.input`
  padding-left: 16px;
  width: 300px;
  height: 50px;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(
    -225deg,
    #ac32e4 0%,
    #7918f2 48%,
    #4801ff 100%
  );

  color: #e2e2e2;
`
export const CoinContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CoinRow = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #d7d7d7;
`
export const Coins = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
  min-width: 300px;
`
export const CoinImage = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;
`
export const CoinName = styled.h3`
  font-size: 16px;
  width: 150px;
`
export const CoinSymbol = styled.p`
  text-transform: uppercase;
  @media screen and (max-width: 460px) {
    margin-left: 20px;
    display: none;
  }
`
export const CoinsData = styled.div`
  display: flex;
  text-align: right;
  justify-content: space-between;
  width: 100%;
`
export const CoinPrice = styled.p`
  width: 110px;
  @media screen and (max-width: 460px) {
    margin-left: -200px;
  }
`
export const CoinVolume = styled.p`
  width: 155px;
  @media screen and (max-width: 460px) {
    display: none;
  }
`

export const CoinPriceChange = styled.p`
  width: 155px;
  @media screen and (max-width: 460px) {
    margin-left: -30px;
  }
`
export const CoinPercentRed = styled.p`
  width: 100px;
  color: #f00606;
  @media screen and (max-width: 460px) {
    margin-left: -150px;
  }
`
export const CoinPercentGreen = styled.p`
  width: 100px;
  color: #11d811;
  @media screen and (max-width: 460px) {
    margin-left: -150px;
  }
`
export const CoinMarketCap = styled.p`
  width: 230px;
  @media screen and (max-width: 460px) {
    display: none;
  }
`

export const NewsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
`
export const NewsWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
export const NewsSideBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 35%;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`
export const NewsSideBarContainer = styled.div`
  padding: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`
export const NewSidebarHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`
export const SideNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-bottom: 1px solid red;
  height: auto;
`
export const SideNewsLink = styled.a`
  font-size: 18px;
  text-decoration: none;
  color: var(--primaryColor);
  font-weight: 600;
`
export const SideNewsSource = styled.p`
  color: black;
  font-size: 18px;
`
export const SidebarNewsPagination = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  padding: 10px;
  justify-content: center;
  align-items: center;
`
export const PreviousBtn = styled.button`
  font-size: 14px;
  padding: 5px;
`
export const NextBtn = styled.button`
  font-size: 14px;
  padding: 5px;
`
export const PageNumberDisplay = styled.p`
  font-size: 14px;
  padding: 5px;
`
export const NewsSidebarContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`

export const ViewMoreNewsBtn = styled(LinkS)`
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  margin: 10px;
  :hover {
    color: blue;
  }
`
export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 460px) {
    width: 100%;
  }
`
export const AllNews = styled.div`
  width: 100%;
  padding: 10px;
`
export const SidebarAllNews = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`
export const SidebarAllNewsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
export const NewsLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
`
export const SidebarAllNewsCard = styled.div`
  width: 300px;
  border-radius: 10px;
  margin: 5px;
  padding: 8px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`

export const SidebarAllNewsImg = styled.img`
  width: 100%;
`
export const SidebarAllNewsCardContent = styled.div``
export const SidebarAllNewsCardSource = styled.p``
export const SidebarAllNewsCardDate = styled.p``
export const SidebarAllNewsCardTitle = styled.h1`
  font-size: 22px;
`
export const AllMainNews = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`
