import React, { useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser'

import {
  ContactContainer,
  ContactWrapper,
  ContactRow,
  Column1,
  TextWrapper,
  Heading,
  SubHeading,
  ContactDetails,
  ContactDetail,
  DetailsItem,
  ImgWrap,
  Img1,
  Img2,
  Title,
  Detail,
  Column2,
  FormContainer,
  Form,
  FormItems,
  FormItem,
  LabelA,
  InputName,
  InputEmail,
  InputCountry,
  InputMessage,
  InputSubject,
  BtnWrap,
  BtnSendMsg,
} from './ContactsElements'
//animation kits
import Aos from 'aos'
import 'aos/dist/aos.css'
const Contacts = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_dikz8uj',
        'template_spus4kk',
        form.current,
        'MgDhCN83jAuqyUwFb'
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log('message sent')
        },
        (error) => {
          console.log(error.text)
        }
      )
    form.current.reset()
  }
  return (
    <>
      <ContactContainer id='id_contactus'>
        <ContactWrapper>
          <ContactRow>
            <Column1 data-aos='slide-right'>
              <TextWrapper>
                <Heading>Did we miss something?</Heading>
                <SubHeading>Contact Us</SubHeading>
                <ContactDetails>
                  <DetailsItem>
                    <ImgWrap>
                      <Img2 />
                    </ImgWrap>
                    <ContactDetail>
                      <Title>Email</Title>
                      <Detail>admin@thecryptoplayground.com</Detail>
                    </ContactDetail>
                  </DetailsItem>
                </ContactDetails>
              </TextWrapper>
            </Column1>
            <Column2 data-aos='slide-left'>
              <FormContainer>
                <Form ref={form} onSubmit={sendEmail}>
                  <FormItems>
                    <FormItem>
                      <LabelA>Name</LabelA>
                      <InputName name='user_name' />
                    </FormItem>
                    <FormItem>
                      <LabelA>Email</LabelA>
                      <InputEmail name='user_email' />
                    </FormItem>
                    <FormItem>
                      <LabelA>Country</LabelA>
                      <InputCountry name='user_country' />
                    </FormItem>
                  </FormItems>

                  <LabelA>Subject</LabelA>
                  <InputSubject name='user_subject' />
                  <LabelA>Message</LabelA>
                  <InputMessage name='user_message' />
                  <BtnWrap>
                    <BtnSendMsg type='submit'>Send Message</BtnSendMsg>
                  </BtnWrap>
                </Form>
              </FormContainer>
            </Column2>
          </ContactRow>
        </ContactWrapper>
      </ContactContainer>
    </>
  )
}

export default Contacts
