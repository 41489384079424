import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import {
  FooterContainer,
  FooterWrapper,
  FooterLogoContainer,
  FooterImg,
  TnP,
  FooterCopyright,
  FooterText,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLink2,
} from './FooterElements'

const Footer = () => {
  // Scrolling to top of the page
  const toggleHome = () => {
    scroll.scrollToTop()
  }
  return (
    <>
      <FooterContainer id='id_footer'>
        <FooterWrapper>
          <FooterLogoContainer>
            <FooterImg
              to='/'
              src={require('../assets/images/logo-img.png')}
              alt=''
              onClick={toggleHome}
            />
            <TnP>
              <FooterText to='/termscondition'>Terms and Condition</FooterText>
              <FooterText to='/privacy'>Privacy Policy</FooterText>
            </TnP>
          </FooterLogoContainer>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle> Community </FooterLinkTitle>
                <FooterLink to='/maintenance'>Social Impacts</FooterLink>
                <FooterLink2
                  to='id_discover'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Governance
                </FooterLink2>
                <FooterLink2
                  to='id_social'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Connect
                </FooterLink2>
                <FooterLink to='/maintenance'>News</FooterLink>
                <FooterLink to='/maintenance'>Press</FooterLink>
                <FooterLink2
                  to='id_contactus'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Contact Us
                </FooterLink2>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle> Playground </FooterLinkTitle>
                <FooterLink2
                  to='id_journeys'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Journeys
                </FooterLink2>
                <FooterLink2
                  to='id_marketplace'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Marketplace
                </FooterLink2>
                <FooterLink2
                  to='id_security'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  Security
                </FooterLink2>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle> Discover </FooterLinkTitle>
                <FooterLink2
                  to='id_faq'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >
                  FAQ
                </FooterLink2>
                <FooterLink to='/maintenance'>Relationship</FooterLink>
                <FooterLink to='/maintenance'>Team</FooterLink>
                <FooterLink to='/maintenance'>Tokenomics</FooterLink>
                <FooterLink to='/maintenance'>Whitepaper</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
        </FooterWrapper>
        <FooterCopyright>
          Copyright &copy; {new Date().getFullYear()}
        </FooterCopyright>
      </FooterContainer>
    </>
  )
}

export default Footer
