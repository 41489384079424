import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonType1 } from '../assets/buttons/Buttons'
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  HeroImg,
  Heading,
  Column2,
  ImgWrap,
  Img,
  BtnWrap,
  Btn,
} from './HeroElements'
const Hero = () => {
  let navigate = useNavigate()
  const routeChange = () => {
    let path = 'https://thecryptoplayground.com/'
    navigate(path)
  }
  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <Heading>Making Crypto</Heading>
                <Heading>Less Cryptic</Heading>
                <HeroImg
                  src={require('../../Components/assets/images/Sally-4.png')}
                  alt=''
                />
                <BtnWrap>
                  <Btn>
                    <a href='https://thecryptoplayground.com/wp-login.php?'>
                      <ButtonType1>Sign Up</ButtonType1>
                    </a>
                  </Btn>
                  <Btn>
                    <a href='https://thecryptoplayground.com/wp-login.php?'>
                      <ButtonType1 onClick={routeChange}>Sign In</ButtonType1>
                    </a>
                  </Btn>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={require('../assets/images/herobg.png')} alt='' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Hero
