import React from 'react'
import styled from 'styled-components'
import { cryptonews_api } from '../../assets/Datas/NewsApi2'

const MainCardContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
`
const MainCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid black;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
const MainCardImg = styled.img`
  width: 250px;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
`
const MainCardContent = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`
const MainCardSource = styled.div`
  display: flex;
  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`
const CardSource = styled.p`
  margin: 0 5px 0 0;
`
const CardDate = styled.p``
const MainCardTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
`
const MainCardDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
`
const ViewMoreNewsBtn = styled.div`
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  margin: 10px;
  :hover {
    color: blue;
  }
`

const MainCard = () => {
  return (
    <>
      <MainCardContainer>
        {cryptonews_api.slice(0, 10).map((item) => {
          return (
            <>
              <MainCardWrapper>
                <MainCardImg src={item.image_url} />
                <MainCardContent>
                  <MainCardSource>
                    <CardSource>{item.source_name}</CardSource>
                    <CardDate>{item.date}</CardDate>
                  </MainCardSource>
                  <MainCardTitle>{item.title}</MainCardTitle>
                  <MainCardDescription>{item.text}</MainCardDescription>
                </MainCardContent>
              </MainCardWrapper>
            </>
          )
        })}
      </MainCardContainer>
    </>
  )
}

export default MainCard
