import React, { useEffect } from 'react'
import {
  InfoContainer,
  InfoWrapper,
  InfoContent,
  Column1,
  TextContent,
  Heading,
  Description,
  ImgScreenWrap,
  ImgScreen,
  ButtonContent,
  Psign,
  BtnWrap,
  BtnImg,
  Column2,
  ImgWrapper,
  Img,
} from './IndexElements'

// animation on the page
import Aos from 'aos'
import 'aos/dist/aos.css'

const HeroSection2 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <InfoContainer id='id_playground' data-aos='fade-up'>
        <InfoWrapper>
          <InfoContent>
            <Column1>
              <TextContent>
                <Heading>Revolutionizing Crypto Education</Heading>
                <Description>
                  The CryptoPlayground reduces the complexity of engagement in
                  the crypto ecosystem by making crypto less cryptic.
                </Description>
                <ImgScreenWrap>
                  <ImgScreen
                    src={require('../../Components/assets/images/Screen.png')}
                    alt=''
                  />
                </ImgScreenWrap>
                <Description>
                  Through a series of five fun playgrounds you can journey
                  individually or within a group to learn how to transition to
                  everyday crypto usage or enhance your skills.
                </Description>
                <Description>
                  Learn how to trade and manage digital assets in our risk free,
                  simulated environment!
                </Description>
              </TextContent>
              <ButtonContent>
                <Psign>Coming soon to...</Psign>
                <BtnWrap>
                  <BtnImg
                    src={require('../assets/images/applestore.png')}
                    alt=''
                  />
                  <BtnImg
                    src={require('../assets/images/googleplay.png')}
                    alt=''
                  />
                </BtnWrap>
              </ButtonContent>
            </Column1>
            <Column2>
              <ImgWrapper>
                <Img src={require('../assets/images/herosection2.png')} />
              </ImgWrapper>
            </Column2>
          </InfoContent>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default HeroSection2
