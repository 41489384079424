import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import Sidebar from '../Components/Sidebar'
import Tokenomic from '../Components/Discover/Tokenomics'

const Tokenomics = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      {/* <Navbar toggle={toggle} info='Discover' /> */}
      {/* <Sidebar isOpen={isOpen} toggle={toggle} info='Discover' /> */}
      <Tokenomic />
    </>
  )
}

export default Tokenomics
