import styled from 'styled-components'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

export const ContactContainer = styled.div`
  overflow: hidden;
  max-width: 1350px;
  margin: auto;
  margin-top: 50px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    padding-top: 50px;
  }
`
export const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  /* @media screen and (max-width: 780px) {
    height: 700px;
  } */
`
export const ContactRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  justify-content: center;
  align-items: center;
  grid-template-areas: 'col1 col2';
  @media screen and (max-width: 486px) {
    grid-template-areas: 'col1' 'col2';
  }
`
export const Column1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  padding-top: 0;
  @media screen and (max-width: 815px) {
    max-width: 500px;
    justify-content: center;
  }
`
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 58px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--primaryColor);
  @media screen and (max-width: 780px) {
    font-size: 32px;
  }
`
export const SubHeading = styled.p`
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 400;
  color: var(--primaryColor);
  @media screen and (max-width: 780px) {
    font-size: 22px;
  }
`
export const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px;
`
export const ImgWrap = styled.div`
  height: 100%;
  width: 20%;
`
export const Img1 = styled(FaPhone)`
  height: 50%;
  width: 50%;
`
export const Img2 = styled(FaEnvelope)`
  height: 50%;
  width: 50%;
`
export const ContactDetail = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 780px) {
    font-size: 14px;
    line-height: 18px;
  }
`
export const Detail = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  @media screen and (max-width: 815px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Column2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  background: #c8c8ed;
  border-radius: 10px;
`
export const FormContainer = styled.div`
  padding: 10px 15px;
  display: block;
  width: 100%;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`
export const FormItems = styled.div`
  display: flex;
  flex-direction: row;
`
export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`
export const LabelA = styled.p`
  position: relative;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s ease;
  color: #666;
`
export const InputName = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border: 2px solid #333;
  }
`
export const InputEmail = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border-bottom: 2px solid #333;
  }
`
export const InputCountry = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border: 2px solid #333;
  }
`
export const InputSubject = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  :focus {
    font-size: 16px;
    border-bottom: 2px solid #333;
  }
`
export const InputMessage = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: 2px solid #333;
  outline: none;
  resize: none;
`
export const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
`
export const BtnSendMsg = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 55px;
  width: 342px;
  height: 52px;
  background: #352e68;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #fff;
`
